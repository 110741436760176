/**
 * These are the constants used in cookie-consent.config
 * @export
 * @constant CONTENT
 */
const HEADER = "<div><h5>Cookies Required</h5><hr class='cc-divider'></div>";
const BODY =
  'Please ensure that your browser is configured to accept third-party cookies. This website requires the use of essential cookies in your browser for user authentication purposes. By using this site, you agree to the placement of these cookies.';
const DISMIS = 'Accept';
const LINK = 'Learn more';
const HREF = 'https://cookiesandyou.com';
const POLICY = 'Cookie Policy';
const MESSAGE = `${HEADER} ${BODY}`;
export const CONTENT = {
  message: MESSAGE,
  dismiss: DISMIS,
  link: LINK,
  href: HREF,
  policy: POLICY,
};
