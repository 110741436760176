import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsAuthenticatedGuard } from 'src/app/core/guards/authentication/is-authenticated.guard';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';


import { ForgotPasswordComponent } from './forgot-password.component';

const routes: Routes = [
    { path: '', component: ForgotPasswordComponent, canActivate: [IsAuthenticatedGuard] },
    { path: '', component: ResetPasswordComponent, canActivate: [IsAuthenticatedGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ForgotPasswordRoutingModule { }
