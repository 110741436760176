import { Component, HostListener, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'epg-base-component',
  template: '',
})
export class BaseComponent implements OnDestroy {
  private _destroy$?: Subject<void>;

  public form!: FormGroup;

  protected takeUntilDestroy = <T>() => {
    if (!this._destroy$) this._destroy$ = new Subject<void>();
    return takeUntil<T>(this._destroy$);
  };

  ngOnDestroy(): void {
    if (this._destroy$) {
      this._destroy$.next();
      this._destroy$.complete();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  public unloadNotification($event: any) {
    if (this.checkFormDirty()) {
      $event.returnValue =
        'This message is displayed in IE and Edge when they navigate without using Angular routing (Type another URL/close the browser/etc)';
    }
  }

  public checkFormDirty() {
    return this.form && this.form.dirty;
  }

  public resetForm() {
    this.form.reset();
    this.form.updateValueAndValidity();
  }
}
