<form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" >
    <div class="login-form">
        <div class="d-flex justify-content-center">
            <div class="mb-3 text-center">
                <div class="brand-login mx-auto mb-4"></div>
                <h3 id="login-header">Enter Account Email</h3>
            </div>
        </div>
        <div class="form-floating mb-3">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email">
                <mat-icon matSuffix>alternate_email</mat-icon>
            </mat-form-field>
        </div>
        <div class="d-grid">
            <button type="submit" mat-flat-button color="primary" class="mt-2 mb-2"
                [disabled]="!forgotPasswordForm.valid">Reset Password</button>
        </div>
        <mat-error *ngIf="errorMessage" class="lh-1 mt-3"><small>{{errorMessage}}</small></mat-error>

        <!-- <div class="text-center mt-4">
            <p class="text-black-50 pt-2 mb-2">Don't have account? <a [routerLink]="['/register']">Register now.</a></p>
            <p class="text-black-50 pt-2 mb-0">Back to <a [routerLink]="['/login']">Login</a></p>
        </div> -->
        <div class="text-center mt-4">
            <button mat-stroked-button class="rm-light me-2" routerLink="/register">Register Here</button>
            <button mat-stroked-button class="rm-light" routerLink="/login">Back to Login</button>
        </div>

        <div class="pt-3 text-danger text-center">{{outputMessage}}</div>
    </div>
</form>
