import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

type ConfiramationAction = {}

@Component({
    selector: 'app-epg-dialog',
    templateUrl: './epg-dialog.component.html',
    styleUrls: ['./epg-dialog.component.scss']
})
export class EpgDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: {
        btnCancelText: string,
        btnOkText: string,
        message: string,
        title: string
    },private dialogRef: MatDialogRef<EpgDialogComponent>) { }

    ngOnInit(): void {
    }

    close() { 
        this.dialogRef.close(false)
    }
    confirm() {
        this.dialogRef.close(true)
     }

}
