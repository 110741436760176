import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Quote } from 'src/app/core/types/quote';
import { QuoteResponse } from 'src/app/core/types/responses/QuoteResponse';
import { EnrolledUnitFileUploadComponent } from 'src/app/modules/espp/enrolled-units/components/enrolled-unit-file-upload/enrolled-unit-file-upload.component';
import { FileCategory } from './claim/claim.service';
export type  FormattedDate = {[key: string]: string}
export type File = {
    id: number
    name: string,
    category: string | null
    url: string
}
export type Coverage = {
    id: number
    coverage_type: string
    coverage: string
    status: { name: string }
    term: number
    hours: number
    effective_date: Date
    expiration_date: Date
    deductible: number | null
    retail_cost: number
    use_type: string
    comments: string
    currency: 'USD' | 'CAD' | 'NZD' | 'AUD'
    quote_type: string,
    date_of_sale: Date,
    purchase_order: string | null,
    formatted_dates: FormatedDate
}

type FormatedDate = {[key: string]: string}
export interface EnrolledUnit {
    id: number
    quote_number: string | null
    date_submitted: Date
    plan_number: string
    plan_type: string
    program?: string
    files: File[],
    invoice_code?: string,
    comments: string[],
    policy_number: string
    documents: any[],
    equipment: {
        year: number,
        manufacturer: string,
        equipment_type: string,
        model: string,
        new: boolean
        equipment_name: string
        serial_number: string | null,
        pin17: string | null
        manufacturer_ship_date: Date
        manufacturer_date: Date
        hours: number
        value: number
        mfr_warranty: string | null
        coverages: Coverage[],
        existing_term: string | null,
        formatted_dates: FormatedDate

    }[]
    dealer: {id: number, dealer_corp_id:number} | null
    customer: {name: string} | null,
    model_type: string,
    formatted_dates: FormattedDate,
    invoice: {invoice_number: string, invoice_date: Date,  formatted_dates: FormatedDate},
    viewing_entity: boolean
}

export interface EnrolledUnitQueueResponse {
    data: EnrolledUnit[]
    meta: {
        current_page: number
        last_page: number
        per_page: number
        total: number
    }
}

@Injectable()
export class EnrolledUnitService {

    public enrolledUnit = new BehaviorSubject<EnrolledUnit | null>(null)
    public isDetailsOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private modal: MatDialog) { }

    getEnrolledUnits(page: number = 1): Observable<EnrolledUnitQueueResponse> {
        return this.http.get<EnrolledUnitQueueResponse>(`api/v1/espp/enrolled_units?page=${page}`)
    }

    getEnrolledUnit(id: number,type: string): Observable<EnrolledUnit> {
        return this.http.get<EnrolledUnit>(`api/v1/espp/enrolled_units/${id}`)
            .pipe(
                tap(enrolledUnit => {
                    this.enrolledUnit.next(enrolledUnit)
                })
            )
    }

    searchEnrolledUnits(term: string): Observable<EnrolledUnitQueueResponse>{
        let params = new HttpParams()
        params = params.append('term',term)
        return this.http.post<EnrolledUnitQueueResponse>(`api/v1/espp/enrolled_units/search`,{term})
    }

    public openFileModal(id){
        this.modal.open(EnrolledUnitFileUploadComponent,{ data: {id }})
    }

    public addFile(id: number,file,category: FileCategory): Observable<File>{
        let formData = new FormData();
        formData.append('file_category', category);
        formData.append('file', file);
        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
            observe: "events",
        };
        //@ts-ignore
        return this.http.post<ArrayBuffer>('api/v1/espp/enrolled_units/'+id+'/files', formData,options)
            .pipe(
                tap( data => {
                    if (data['type'] === HttpEventType.Response) {
                        const file = data['body'] as File
                        const enrolledUnit = this.enrolledUnit.value
                        if (enrolledUnit?.id == id) {
                            enrolledUnit.files.push(file)
                            this.enrolledUnit.next(enrolledUnit)
                        }
                    }
                })
            )
    }


    public checkIfDetailsOpened(){
        let temp = false
        this.isDetailsOpen.subscribe( theData=>{
            temp = theData
        })
        return temp
    }

    public downloadPDF (url: string) {
        window.open(url, "_blank")
    }

}
