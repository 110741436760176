import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { SelectSearchComponent } from './select-search.component';
/**
 * This is the module used for the form control Select Search
 * @exports
 * @class SelectSearchModule
 */
@NgModule({
  declarations: [SelectSearchComponent],
  imports: [
    FormsModule,
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
  ],
  exports: [SelectSearchComponent],
})
export class SelectSearchModule {}
