import { Component, Input } from '@angular/core';
import { Validators } from '@angular/forms';

import { FormControlComponent } from '../form-control/form-control.component';
import { AutocompleteInputOptions } from './autocomplete-input.interface';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
})
export class AutocompleteInputComponent extends FormControlComponent {
  private _options!: AutocompleteInputOptions;

  @Input() public set options(value: AutocompleteInputOptions) {
    this._options = value;
  }

  public get options() {
    return this._options;
  }

  public get isRequired() {
    return this.ngControl?.control?.hasValidator(Validators.required);
  }

  public optionSelectedValue(value: any) {
    this.onChange(value);
    this.options.optionSelected && this.options.optionSelected(value);
  }
}
