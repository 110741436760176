import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorReporterComponent } from '../../components/error-reporter/error-reporter.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastNotificationService } from 'src/app/shared/services/toast-notification/toast-notification.service';
import { MessageType } from 'src/app/shared/services/toast-notification/toast-notification.types';

export type NotificationData = {
    dismissable: boolean,
    message: string,
    snackType: 'Success' | 'Error' | 'Warn' | 'Info'
}

@Injectable()
export class ApiErrorService {

    constructor(
        public dialog: MatDialog,
        private toastNotificationService: ToastNotificationService,
    ) { }

    public reportError(error: Error) {
        const dialogRef = this.dialog.open(ErrorReporterComponent, {
            data: error
        });

        dialogRef.afterClosed().subscribe(() => {

        });
    }

    private readonly _errorSuffix = 'Please contact the program administrator for assistance.';

    /**Gets the suffixe to apply to the error message */
    public get errorSuffix(): string {
        return this._errorSuffix;
    }

    /**
     * Notifies the presentation layer with a snakebar notification that an error has occured
     * @param errorResponse The response from the http call
     */
    public notifyError(errorResponse: HttpErrorResponse): void {
        if (errorResponse.status != 422 && (errorResponse.status >= 400 && errorResponse.status <= 499 || errorResponse.status >= 500 && errorResponse.status <= 599)) {
            let duration: number = this.getDuration(errorResponse.error.message);
            const requestID = errorResponse.headers.get('Request-ID')?.split('-')[0];
            let errorMessage =
                `${errorResponse.error.message} ${this.errorSuffix}\nRequest ID: ${requestID}`;

                const errorCode = errorResponse.error.error_code;
            if(errorCode){
                errorMessage = errorMessage + `\nError ID: ${errorResponse.error.error_code}`;
            }
            this.toastNotificationService.open({
                message: errorMessage,
                type: MessageType.ERROR,
            })
        }
    }

    /**
     * Generates the duration in milliseconds that the message should stay open based off the number of characters for eache character over 120 add a second to the duration.
     * @param message 
     * @returns The duration that the notification should stay visible
     */
    private getDuration(message: string): number {
        let duration: number = 5000;
        const errorSuffix = this.errorSuffix;
        const errorMessage = message + ' ' + errorSuffix;
        if (errorMessage.length + 1 >= 120) {
            const errorMessageAdditionalLength = Math.floor((errorMessage.length + 1) / 120);
            duration = + errorMessageAdditionalLength * 1000
        }
        return duration;
    }
}
