import { BrowserModule } from '@angular/platform-browser';
import { inject, Injector, NgModule } from '@angular/core';


import { Router } from '@angular/router';
import { OKTA_CONFIG } from '@okta/okta-angular';
import OktaAuth, { OktaAuthOptions } from '@okta/okta-auth-js';
import config from './../okta.config';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './components/login-form/login-form.component';

// export default {
//     oidc: {
//       clientId: `0oa1f9ffd38nw98Y80h8`,
//       issuer: `https://idm-dev.assurant.com`,
//       redirectUri: window.location.pathname + '//' + window.location.hostname +'/sso',
//       scopes: ['openid', 'email', 'profile','groups'],
//       testing: {
//         disableHttpsCheck: false
//       },
//     },
//     widget: {
//       useInteractionCodeFlow: false,
//     },
//     resourceServer: {
//       messagesUrl: 'http://localhost:8000/api/messages',
//     },

//   };
@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        {
            //deps: [AppConfigService],
            provide: OKTA_CONFIG,
            useFactory: () => {
                const settings = require('../../assets/config/config.json');
                //const settings = inject(AppConfigService);
                //console.log(window.location.protocol + '//' + window.location.hostname + '/login/callback')
                const oktaConfig: OktaAuthOptions = {
                    clientId: settings.oktaConfig.clientId,
                    issuer: settings.oktaConfig.issuer,
                    redirectUri: window.location.protocol + '//' + window.location.hostname + '/login/callback',
                    scopes: settings.oktaConfig.scopes,
                };
                //console.log(oktaConfig)
                const oktaAuth = new OktaAuth(config.oidc);
                return {
                    oktaAuth,
                    onAuthRequired: (oktaAuth, injector: Injector) => {
                        // const triggerLogin = () => {
                        //     // Redirect the user to your custom login page
                        //     const router = injector.get(Router);
                        //     router.navigate(['login']);
                        // };
                        // const openDialogForm = () => {
                        //     const dialog: MatDialog = injector.get(MatDialog);
                        //     const loginForm = dialog.openDialogs.find(ref => ref.componentInstance instanceof LoginFormComponent);
                        //     if (loginForm) return;
                        //     dialog.open(LoginFormComponent, { disableClose: true, closeOnNavigation: false })
                        // };
                        // if (!oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated) {
                        //     // App initialization stage
                        //     triggerLogin();
                        // } else {
                        //     openDialogForm();
                        //     sessionStorage.removeItem('user')
                        //     // Ask the user to trigger the login process during token autoRenew process
                        //     // const modalService = injector.get(SuiModalService);
                        // }
                    },
                    //https://stg-portal.cnh.com/isamdp/sps/DPFederation/saml20/logininitial?RequestBinding=HTTPPost&PartnerId=https://www.okta.com/saml2/service-provider/sptqwytwhkpdjudkyygu&NameIdFormat=Email&Target=https://espp-integration-model.epgins.com/login/callback
                    onAuthResume: (oktaAuth, injector: Injector) => {
                        // console.log('here: ',oktaAuth)
                        // // Use injector to access any service available within your application
                        // const router = injector.get(Router);
                        // //console.log(oktaAuth)
                        // // Redirect the user to custom login page which renders the Okta SignIn Widget
                        // router.navigate(['/login']);
                    }
                }
            }
        },
    ],
})
export class OktaModule { }
