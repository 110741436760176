import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsAuthenticatedGuard } from './core/guards/authentication/is-authenticated.guard';
import { ForgotPasswordComponent } from './modules/forgot-password/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/forgot-password/components/reset-password/reset-password.component';
import { HomeModule } from './modules/home/home.module';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
    },
    {
        path: '',
        loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    // {
    //     path: 'register',
    //     loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule)
    // },
    {path: 'register', redirectTo: 'register/us', pathMatch: 'full'},
    {
        path: 'register/:countryCode',
        loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule)
    },
    // {
    //     path: 'forgot_password',
    //     component: ForgotPasswordComponent,
    //     canActivate: [IsAuthenticatedGuard]
    // },
    // {
    //     path: 'password/reset/:token',
    //     component: ResetPasswordComponent,
    //     canActivate: [IsAuthenticatedGuard]
    // },
    //   {
    //     path: '',
    //     loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
    //   },
    //   {
    //     path: 'reset_password',
    //     loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
    //   },
    {
        path: 'apps',
        loadChildren: () => import('./modules/apps/apps.module').then(m => m.AppsModule),
        canActivate: []
    },
    { path: 'TestQueueModule', loadChildren: () => import('./modules/test-queue-module/test-queue-module.module').then(m => m.TestQueueModuleModule) },
    {
        path: '**',
        loadChildren: () => import('./modules/error-page/error-page.module').then(m => m.ErrorPageModule),
        data: { message: 'Page not found', code: 404 }
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
