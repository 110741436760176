import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
type UseType = {use_type: string, can_quote: boolean}
@Injectable()
export class UseTypeService {

    public useTypes: BehaviorSubject<{ [key: string]: string  | boolean }[]> = new BehaviorSubject<{ [key: string]: string  | boolean }[]>([])

    constructor(private http: HttpClient) { }

    public getUseTypes(mfrProgram: string): Observable<UseType[]> {
        return this.http.get<UseType[]>('api/v1/espp/use_types')
                .pipe( tap( values => this.useTypes.next(values)))
    }
}
