import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { ToastNotificationService } from 'src/app/shared/services/toast-notification/toast-notification.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private authService: AuthenticationService,
    private toastNotificationService: ToastNotificationService
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<Object>> {
    let authReq = req;
    const token = this.authService.token;
    if (token) authReq = this.addTokenHeader(req, token);
    return next.handle(authReq).pipe(
      catchError((error) => {
      const refreshToken = this.authService.refreshToken;
      if (
          error instanceof HttpErrorResponse &&
          !authReq.url.includes('/login') &&
          refreshToken &&
          error.status === 401
        ) {
          return this.handle401Error(authReq, next);
        }
        return throwError(error);
      })
    );
  }
  /**
   * @description Refresh token if expired
   * @param request
   * @param next
   * @returns Observable
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = this.authService.refreshToken;
      if (refreshToken) {
        return this.authService.tokenRefresh().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.authService.updateToken(token);
            this.refreshTokenSubject.next(token.access_token);
            return next.handle(
              this.addTokenHeader(request, token.access_token)
            );
          }),
          catchError((error: any) => {
            this.isRefreshing = false;
            this.toastNotificationService.open({
              message: 'Your session has expired',
            });
            this.authService.logout();
            return throwError(error);
          })
        );
      }
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }
  /**
   * @description Set Authorization header with token
   * @param request
   * @param token
   * @returns Authorization header
   */
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }
}
