<mat-expansion-panel *ngFor="let item of debuggableKeys">
    <mat-expansion-panel-header>
        <!-- <mat-panel-description>
           Component data
        </mat-panel-description> -->
        <mat-panel-title>
            {{item}}
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
        <span><h5>{{item}}</h5></span>
        <ng-container *ngFor="let item of flatten(debugabbles.get(item)) | keyvalue">
            <div >{{'\t\t'+item.key}}: {{item.value}}</div>

        </ng-container>
    </div>
</mat-expansion-panel>

<button mat-button (click)="download()">Download data</button>