<div class="container-fluid login-container">
    <div class="container-login100">
        <div class="row">
            <div class="col login-graphic">
                <div class="login-overlay-text">
                    <div class="login-overlay-subtext"></div>
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center col p-0 col p-0">
                <app-login-form></app-login-form>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>