import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { even } from '@rxweb/reactive-form-validators';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClaimService, FileCategory } from 'src/app/shared/services/claim/claim.service';

@Component({
    selector: 'app-file-upload-form',
    templateUrl: './file-upload-form.component.html',
    styleUrls: ['./file-upload-form.component.scss']
})
export class FileUploadFormComponent implements OnInit {

    public fileForm = new FormGroup({
        file: new FormControl(null, [Validators.required]),
        file_category: new FormControl('GENERAL', [Validators.required]),
        has_outside_invoice: new FormControl(false,[Validators.required])
    })
    public errorMessage = new BehaviorSubject<string | null>(null);
    public fileName = '';
    public progress = 0;
    public uploading = false;

    constructor(
        private claimService: ClaimService,
        private matDialog: MatDialogRef<FileUploadFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { id: number, outside_labor: boolean }
    ) { }

    ngOnInit(): void {
    }

    public get files() {
        if (this.fileList) {
            return Array.prototype.slice.call(this.fileList)
        }
        return [];
    }

    fileList: FileList | undefined;

    onFileSelected(event) {
        this.fileList = event.target.files;
    }

   
    public fileErrors: Map<number, string[]> = new Map<number, string[]>();
    public getFileError(index) {
        return this.fileErrors.get(index)
    }

    uploadFile() {
        this.uploading = true;
        this.errorMessage.next(null)
        const hasOutsideInvoice = this.fileForm.controls.has_outside_invoice.value == true? '1' : '0';
        if (this.fileForm.valid) {
            const chunkSize = 10;
            for (let i = 0; i < this.files.length; i += chunkSize) {
                const chunk = this.files.slice(i, i + chunkSize);

                this.claimService.addFile(this.data.id, chunk, FileCategory.GENERAL,hasOutsideInvoice)
                    .subscribe(
                        data => {
                            if (data['type'] === HttpEventType.Response) {
                                this.fileForm.enable()
                                this.matDialog.close()
                                this.uploading = false;
                            }

                        },
                        errorResponse => {
                            (Object.keys(errorResponse.error.errors)).forEach((key) => {
                                const errorKey = parseInt(key.split('.')[1]);
                                if (this.fileErrors.has(errorKey)) {
                                    const current = this.fileErrors.get(errorKey);
                                    if (current)
                                        this.fileErrors.set(errorKey, [...current, ...errorResponse.error.errors[key]]);
                                } else {
                                    const errors = (errorResponse.error.errors[key])
                                    const fileIndex = errorKey;
                                    this.fileErrors.set(errorKey, [...errors.map((error: string) => {
                                        return error.replace(key,this.files[fileIndex].name);
                                    })]);
                                }
                            });

                            this.uploading = false;
                            this.fileForm.enable()
                            this.errorMessage.next(errorResponse.error.message)
                        }
                    )
            }
        }
    }
}
