import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DownloadPDFOptions } from './download-pdf.interface';
import { DownloadPDFComponent } from './download-pdf-component/download-pdf.component';
/**
 * Download PDF Service
 * @export
 * @class DownloadPDFService
 */
@Injectable({
  providedIn: 'root',
})
export class DownloadPDFService {
  constructor(private dialog: MatDialog) {}
    /**
     * Open a small dialog to download a PDF with different languages
     */
  public open(options: DownloadPDFOptions[]) {
    this.dialog.open(DownloadPDFComponent, {
      width: '300px',
      data: options,
    });
  }
}
