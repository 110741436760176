import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

//   forgotPasswordForm: FormGroup = new FormGroup({
//     email: new FormControl(null, [Validators.required, Validators.email]),
//   });

//   outputMessage: string | null = null;
//   status = 0;

//   get email(): AbstractControl {
//     return this.forgotPasswordForm.controls.email;
//   }

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
  }

//   onSubmit(): void {
//     if(!this.forgotPasswordForm.valid){ return; }
//     this.authService.forgotPassword(this.email.value)
//         .subscribe(
//           response => {
//             this.outputMessage = `An email was sent to ${response.email}`;
//             this.status = 200;
//           },
//           error => {
//             this.outputMessage = error;
//             this.forgotPasswordForm.reset();
//             this.status = 422;
//           }
//         );
//   }

}
