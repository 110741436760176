import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DownloadPDFOptions } from '../download-pdf.interface';
import { Observable, isObservable } from 'rxjs';
import { QuoteService } from '../../quote.service';

@Component({
  selector: 'download-pdf-component',
  templateUrl: './download-pdf.component.html',
})
export class DownloadPDFComponent {
  public pdf!: string;
  constructor(
    public dialogRef: MatDialogRef<DownloadPDFComponent>,
    public quoteService: QuoteService,
    @Inject(MAT_DIALOG_DATA) public data: DownloadPDFOptions[]
  ) {}

  public getOptionLabel(option: Observable<string | any> | string) {
    return isObservable(option)
      ? option.subscribe((res) => res)
      : option;
  }

  public onDownload(): void {
    this.pdf && this.quoteService.downloadPDF(this.pdf)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
