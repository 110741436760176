<div class="fixed-width-modal">
    <form enctype="multipart/form-data" [formGroup]="fileForm" (ngSubmit)="uploadFile()">
        <h1 mat-dialog-title class="mb-1">Upload File</h1>
        <div mat-dialog-content class="mb-3"><small>Complete the form and select a file that you would like to
                upload.</small></div>
        <div mat-dialog-content class="mb-3" *ngIf="errorMessage| async"><small>{{errorMessage | async}}</small></div>
        <div class="row">
            <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>File Type</mat-label>
                    <mat-select formControlName="file_category">
                        <mat-option value="GENERAL">General</mat-option>
                        <mat-option value="OIL_ANALYSIS">Outside Invoice</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload
                    formControlName="file">

                <div class="file-upload">
                    <button mat-icon-button color="primary" class="upload-btn me-1" type="button"
                        (click)="fileUpload.click()">
                        <mat-icon>attach_file</mat-icon>
                    </button>

                    {{fileName || "Click paper clip to select file."}}
                </div>
            </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-auto">
                <div mat-dialog-actions>
                    <button mat-flat-button color="primary" class="mb-4" mat-dialog-close>
                        Cancel
                    </button>
                </div>
            </div>
            <div class="col-auto ps-0">
                <div mat-dialog-actions>
                    <button mat-flat-button color="primary" class="mb-4" type="submit">
                        Upload
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>