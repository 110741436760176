import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';
import { QuotePermission } from 'src/app/core/permissions/QuotePermission';
import { User } from 'src/app/shared/models/User';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginWrapperComponent implements OnInit {
    loginForm: FormGroup = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email]),
        password: new FormControl(null, [Validators.required, Validators.minLength(8)])
    });

    public hide = true;
    public resetPassword: boolean = false;
    outputMessage: string | null = null;
    status = 0;

    errorMessage: string | null = null;
    signIn: any;
    constructor(
        private router: Router,
        private authService: AuthenticationService,
        @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {}

    ngOnDestroy() {}

    /**
     * OnLoginClick
     */
    OnregisterClick(): void {
        this.router.navigate(['/register']);
    }

    resetPassowrd(): void {
        this.resetPassword = !this.resetPassword;
    }

}
