import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EsppQuoterComponent } from './espp-quoter.component';
import { QuoteTypeService } from './services/quote-type/quote-type.service';
import { QuoteTypeResolver } from './reslovers/quote-type/quote-type.resolver';
import { ReactiveFormsModule } from '@angular/forms';
import { VolvoModelValidator } from './validators/volvo-model-validator'
import { CoreModule } from 'src/app/core/core.module';
import { SaleTypeService } from './services/sale-type/sale-type.service';
import { Pin17Service } from './services/pin17/pin17.service';
import { EquipmentTypeService } from './services/equipment-type/equipment-type.service';
import { EquipModelService } from './services/equip-model/equip-model.service';
import { CoverageTermService } from './services/coverage-term/coverage-term.service';
import { ManufacturerService } from './services/manufacturer/manufacturer.service';
import { UseTypeService } from './services/use-type/use-type.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from 'src/app/shared/material.module';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { SharedModule } from 'src/app/shared/shared.module';
import { QuoteErrorInterceptor } from './interceptors/quote-error.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SelectOptionDirective } from './directive/select-option.directive';
import { AutocompleteInputModule } from 'src/app/shared/components/autocomplete-input';
import { SelectSearchModule } from 'src/app/shared/components/select-search';
import { DealerService } from './services/dealer/dealer.service';
import { InputModule } from 'src/app/shared/components/input';
import { SerialNumberService } from './services/serial_number/serial-number.service';


@NgModule({
  declarations: [EsppQuoterComponent, SelectOptionDirective],
  imports: [
    InputModule,
    SelectSearchModule,
    AutocompleteInputModule,
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    CoreModule,
    RxReactiveFormsModule,
  ],
  exports: [EsppQuoterComponent],
  providers: [
      //services
      DealerService,
      QuoteTypeService,
      SaleTypeService,
      Pin17Service,
      EquipmentTypeService,
      EquipModelService,
      CoverageTermService,
      ManufacturerService,
      UseTypeService,
      SerialNumberService,
      //resolvers
      QuoteTypeResolver, 
      
      //validators
      VolvoModelValidator,
      
      //modules
      BrowserAnimationsModule,
      //QuoteErrorInterceptor,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: QuoteErrorInterceptor,
        multi: true
      }
    ]
})
export class EsppQuoterModule { }
