import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { IsAuthenticatedGuard } from 'src/app/core/guards/authentication/is-authenticated.guard';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

import { LoginWrapperComponent } from './login.component';

const routes: Routes = [
  {
    path: '',
    component: LoginWrapperComponent,
    data: { title: 'login' },
    canActivate: [IsAuthenticatedGuard],
    children: [
      { path: '', component: LoginComponent },
      //{ path: 'callback', component: OktaCallbackComponent },
      { path: 'forgot_password', component: ForgotPasswordComponent },
      { path: 'password_reset/:token', component: ResetPasswordComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
