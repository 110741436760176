<h3 mat-dialog-title class="mb-2">Select Language</h3>
  <div mat-dialog-content>
    <mat-form-field class="example-full-width mt-2" appearance="outline">
      <mat-label>Language</mat-label>
      <mat-select [(value)]="pdf">
        <mat-option *ngFor="let option of data" [value]="option.url">
          {{ getOptionLabel(option.label) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end">
    <button class="mt-0 mb-2" mat-flat-button color="primary" (click)="onDownload()">
      Download
    </button>
  </div>
