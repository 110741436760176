import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';

@Component({
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    forgotPasswordForm: FormGroup = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email]),
      });

      public hide = true;
      public resetPassword: boolean = false;
      outputMessage: string | null = null;
      status = 0;
  
      errorMessage: string | null = null;
  
      constructor(
          private router: Router,
          private authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  get email(): AbstractControl {
    return this.forgotPasswordForm.controls.email;
}
  
  onSubmit(): void {
    if(!this.forgotPasswordForm.valid){ return; }
    this.authService.forgotPassword((this.email.value).toLowerCase())
        .subscribe(
          response => {
            this.outputMessage = `An email was sent to ${response.email}`;
            this.status = 200;
          },
          error => {
            this.outputMessage = error;
            this.forgotPasswordForm.reset();
            this.status = 422;
          }
        );
  }
}
