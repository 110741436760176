import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { USER_INFO } from 'src/app/shared/api.constants';
import { User } from 'src/app/shared/models/User';
import { Router } from '@angular/router';
import { Permission } from './permissions.types';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  public permissions: string[] = [];

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private router: Router
  ) {}

  public hasPermission(authPermissions: string[]): boolean {
    // Check authentication
    if (!this.authService.isAuthenticated) {
      this.router.createUrlTree(['/login']);
      return false;
    }
    // Check ALL permissions flag
    if (authPermissions.includes(Permission.ALL)) {
      return true;
    }
    // Check latest permissions
    if (
      this.permissions.length > 0 &&
      this.permissions.find((permission) => {
        return authPermissions.includes(this.toUpperCase(permission));
      })
    ) {
      return true;
    }
    return false;
  }
  /**
   * Format any string value to standardized permission string: 'create quote' -> 'CREATE_QUOTE'
   * @param word string
   * @returns string
   */
  private toUpperCase(word: string): string {
    return word.toUpperCase().split(' ').join('_');
  }
  public setPermissions(permissions: string[]) {
    this.permissions = permissions;
  }
  /**
   * @param user
   * @returns Observable
   */
  public initializePermissions(user?: User) {
    if (user) {
      this.permissions = user.permissions.map((permission) => permission.name);
    }
    return new Promise<void>((resolve, _reject) => {
      return this.http.get<User>(USER_INFO).subscribe((user) => {
        const permissions = user.permissions?.map(
          (permissions) => permissions.name
        );
        this.setPermissions(permissions)
        resolve();
      });
    });
  }
}
