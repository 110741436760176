<div class="fixed-width-modal p-3">
    <form enctype="multipart/form-data" [formGroup]="fileForm" (ngSubmit)="uploadFile()">
        <h1 mat-dialog-title class="mb-1">File Upload</h1>
        <div mat-dialog-content class="mb-4"><small>Select the file(s) that you would like to upload for this claim.</small></div>
        <div mat-dialog-content class="mb-3" *ngIf="errorMessage| async"><small>{{errorMessage | async}}</small></div>
        <div class="row">
            <div class="col-12">
                <input type="file" class="file-input" accept=".jpg,.jpeg,.png,.pdf,.heic,.xls,.xlsx,.doc,.docx,.txt,.csv,.mp4" 
                    (change)="onFileSelected($event)" #fileUpload
                    formControlName="file" multiple>
                <div class="file-upload">
                    <button mat-flat-button color="primary" class="mb-3" type="button"
                        (click)="fileUpload.click()">
                        Select Files
                    </button>
                    <br>
                    <!-- {{fileName || "No files selected."}} -->
                    <div class="file-list mb-3" *ngFor="let file of files; let i=index">
                        {{file.name || "No files selected."}}
                        <ng-container *ngFor="let error of getFileError(i)">
                                <div class="mb-3">{{error}}</div>
                        </ng-container>
                        <!-- | {{file.type}} -->
                    </div>
                </div>
                <!-- <hr> -->
            </div>
            <!-- This section should only appear if an amount is entered into the Sublet Amount field. Will said if it's 
            there and just disabled, people will get confused and call about how they can't click the box. LOL -->

            <!-- TODO Refactor Checkbox -->
            <!-- <section class="" *ngIf="data.outside_labor">
                <mat-checkbox class="" formControlName="has_outside_invoice">Sublet Invoice Attached</mat-checkbox>
                <p class="small pt-2">If you have entered an amount in the Sublet Amount field, an invoice for this work is required to submit your claim.</p>
            </section> -->
            <p class="text-muted"><small>Accepted file types: .jpg, .jpeg, .png, .pdf, .heic, .xls, .xlsx, .doc, .docx, .txt, .csv, .mp4</small></p>
            <p class="text-muted"><small>All supporting documentation, such as the sublet invoice, failure photos, and maintenance records (when required), 
                should be attached in order for the claim to be processed.</small></p>
        </div>
        <div class="row justify-content-end">
            <div class="col-auto">
                <div mat-dialog-actions>
                    <button mat-flat-button color="primary" mat-dialog-close [disabled]="uploading">
                        Cancel
                    </button>
                </div>
            </div>
            <div class="col-auto ps-0">
                <div mat-dialog-actions>
                    <button mat-flat-button color="primary" type="submit" [class.spinner]="uploading" [disabled]="uploading">
                        Upload
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>


<!-- <div class="fixed-width-modal p-3">
    <form enctype="multipart/form-data" [formGroup]="fileForm" (ngSubmit)="uploadFile()">
        <h1 mat-dialog-title class="mb-1">File Upload</h1>
        <div mat-dialog-content class="mb-4"><small>Select the file(s) that you would like to upload for this claim.</small></div>
        <div mat-dialog-content class="mb-3" *ngIf="errorMessage| async"><small>{{errorMessage | async}}</small></div>
        <div class="row">
            <div class="col-md-8 col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>File Type</mat-label>
                    <mat-select formControlName="file_category">
                        <mat-option value="GENERAL">General</mat-option>
                        <mat-option value="OUTSIDE_INVOICE">Outside Invoice</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <input type="file" class="file-input" accept=".jpg,.png,.pdf" (change)="onFileSelected($event)" #fileUpload
                    formControlName="file">

                <div class="file-upload">
                    <button mat-icon-button color="primary" class="upload-btn me-1" type="button"
                        (click)="fileUpload.click()">
                        <mat-icon>attach_file</mat-icon>
                    </button>

                    {{fileName || "Click paper clip to select file."}}
                </div>
            </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-auto">
                <div mat-dialog-actions>
                    <button mat-flat-button color="primary" mat-dialog-close>
                        Cancel
                    </button>
                </div>
            </div>
            <div class="col-auto ps-0">
                <div mat-dialog-actions>
                    <button mat-flat-button color="primary" type="submit" [class.spinner]="uploading" [disabled]="uploading">
                        Upload
                    </button>
                </div>
            </div>
        </div>
    </form>
</div> -->