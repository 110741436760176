import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
type  EquipmentType = {[key: string]: string}
@Injectable()
export class EquipmentTypeService {

  constructor(private http: HttpClient) { }

  public getEquipmentType(program: string, saleType: string, manufacturer: string,ratingType: string): Observable<EquipmentType[]>{
    let params = new HttpParams();
    params = params.append('manufacturer', manufacturer);
    params = params.append('program', program);
    params = params.append('quote_type', saleType);
    params = params.append('rating_type', ratingType);
    return this.http.get<EquipmentType[]>('api/v1/espp/equipment_types',{params});
  }
}
