import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { AuthenticationService } from './modules/authentication/authentication.service';

@Injectable()
export class LocaleService {

    //Chosse Locale From This Link
    //https://github.com/angular/angular/tree/master/packages/common/locales
    //https://www.titanwolf.org/Network/q/4545c33f-72df-4893-9b8c-68cb663131e0/y
    constructor(private authService: AuthenticationService) {

    }

    getLocale(): string {
        // if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
        //   return undefined;
        // }

        // const userJson = localStorage.getItem('user');
        // if(userJson){
        //     const user: {locale: string} = JSON.parse(userJson);
        //     return user.locale;
        // }
    
        let browserLang: any = window.navigator['languages'] ? window.navigator['languages'][0] : null;
        browserLang = browserLang || window.navigator.language || window.navigator['browserLanguage'] || window.navigator['userLanguage'];
    
        if (browserLang.indexOf('-') !== -1) {
          browserLang = browserLang.split('-')[0];
        }
    
        if (browserLang.indexOf('_') !== -1) {
          browserLang = browserLang.split('_')[0];
        }

        return browserLang;
      }
}