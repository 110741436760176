import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { OktaAuthStateService } from '@okta/okta-angular';
import { combineLatest, Observable, of } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/authentication.service';
import { ClaimPermission } from '../../permissions/ClaimPermission';
import { QuotePermission } from '../../permissions/QuotePermission';

@Injectable({
    providedIn: 'root'
})
export class IsAuthenticatedGuard implements CanActivate, CanActivateChild {
    constructor(private authService: AuthenticationService, private router: Router, private oktaAuth: OktaAuthStateService) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.isAuthenticated();
    }
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.canActivate(childRoute, state);
    }

    private isAuthenticated(): Observable<boolean | UrlTree> {
        return combineLatest([
            this.oktaAuth.authState$,
            this.authService.user
        ])
            .pipe(
                take(1),
                switchMap((auth): Observable<boolean | UrlTree> => {
                    const oktaAuth: boolean = auth[0].isAuthenticated ?? false;
                    const isAuth: boolean = auth[1] !== null

                    if (oktaAuth && isAuth) { 
                        return of(this.authService.navigateToLandingPage()); 
                    }
               
                    return of(true);
                })
            )
    }

}
