import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
type Model = { [key: string]: string }

@Injectable()
export class EquipModelService {

    constructor(private http: HttpClient) { }

    public getModels(program: string, saleType: string, manufacturer: string, equipType: string,ratingType: string): Observable<Model[]> {
        let params = new HttpParams();
        params = params.append('manufacturer', manufacturer);
        params = params.append('program', program);
        params = params.append('quote_type', saleType);
        params = params.append('equip_type', equipType);
        params = params.append('rating_type', ratingType);
        return this.http.get<Model[]>('api/v1/espp/models',{params});
    }
}
