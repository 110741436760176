import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { AutocompleteInputComponent } from './autocomplete-input.component';

/**
 * This is the module used for the form control autocomplete input
 * @export
 * @class AutocompleteInputModule
 */
@NgModule({
  declarations: [AutocompleteInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
  ],
  exports: [AutocompleteInputComponent],
})
export class AutocompleteInputModule {}
