import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class QuoteTypeService {

  constructor(private http: HttpClient) { }

  public getQuoteTypes(): Observable<{[key: string]: string}[]>{
      return this.http.get<{[key: string]: string}[]>('api/v1/espp/quote_types')
            .pipe(
                catchError( error => of([]))
            )
  }
}
