<div class="p-3">
    <h1 mat-dialog-title>
        {{title}}
    </h1>
  
    <div mat-dialog-content>
        <p>{{message}}</p>
    </div>
  
    <div mat-dialog-actions>
        <button mat-flat-button (click)="onDismiss()">No</button>
        <button mat-flat-button color="primary" (click)="onConfirm()">Yes</button>
    </div>
</div>