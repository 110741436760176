import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export type EquipmentStatus = { [key: string]: string; };

@Injectable({
  providedIn: 'root'
})
export class EquipmentStatusService {

  constructor(private http: HttpClient) { }

  public getEquipmentStatuses(manufacturer: string): Observable<EquipmentStatus[]>{
        return this.http.get<EquipmentStatus[]>('api/v1/espp/equipment_status')
            .pipe(
                catchError( errorResponse => {
                    return throwError([])
                })
            )
  }
}
