import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EpgDialogComponent } from './epg-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [EpgDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule, 
    MatDialogModule
  ],
  exports: [EpgDialogComponent]
})
export class EpgDialogModule { }
