export enum EsppHeader {
    QUOTE_EXPIRATION = 'Quote Expiration',
    Customer_NAME = 'CUSTOMER_NAME',
    SALESPERSON_NAME = 'Quote Expiration',
    QUOTE_TYPE = 'QUOTE_TYPE',
    SALE_TYPE = 'SALE_TYPE',
    PRIMARY_APPLICATION = 'PRIMARY_APPLICATION',
    USE_TYPE = 'USE_TYPE',
    PIN_17 = 'PIN_17',
    SERIAL_NUMBER = 'SERIAL_NUMBER',
    MANUFACRURER = 'MANUFACRURER',
    EQUIPMENT_TYPE = 'EQUIPMENT_TYPE',
    MODEL = 'MODEL',
    MODEL_YEAR = 'MODEL_YEAR',
    SHIP_DATE = 'SHIP_DATE',
    DELIVERY_DATE = 'DELIVERY_DATE',
    WARRANTY_TERM = 'WARRANTY_TERM',
    WARRANTY_EFFECTIVE = 'WARRANTY_EFFECTIVE',
    WARRANTY_EXPIRATION = 'WARRANTY_EXPIRATION',
    CURRENT_HOURS = 'CURRENT_HOURS',
    COVERGAE_TERM = 'COVERGAE_TERM',
    RETAIL_VALUE = 'RETAIL_VALUE',
    PROGRAM = 'Program',
    PLAN_TYPE = 'PLAN_TYPE',
}
