import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-epg-dialog',
  templateUrl: './epg-dialog.component.html',
  styleUrls: ['./epg-dialog.component.scss']
})
export class EpgDialogComponent {

    title: string;
    message: string;

    constructor(public dialogRef: MatDialogRef<EpgDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogModel) {
        this.title = data.title;
        this.message = data.message;
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }

}


export class ConfirmationDialogModel {
    constructor(public title: string, public message: string) { }
}