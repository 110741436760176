import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SaleTypeService {

    constructor(private http: HttpClient) { }

    public getSaleTypes(quoteType: string): Observable<{ [key: string]: string }[]> {
        const opts = { params: { quote_type: quoteType} };
        return this.http.get<{ [key: string]: string }[]>('api/v1/espp/sale_types',opts)
            .pipe(
                catchError( error => of([]))
            )
    }
}
