import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { AuthorizationService } from '../../auth/authorization.service';
import { Permission } from '../../auth/permissions.types';

@Directive({
  selector: '[hideIfUnauthorized]',
})
export class HideIfUnauthorizedDirective implements OnInit {
  @Input('hideIfUnauthorized') permission: string = '';

  constructor(
    private authorizationService: AuthorizationService,
    private element: ElementRef
  ) {}

  ngOnInit(): void {
    if (!this.authorizationService.hasPermission([this.permission])) {
      this.element.nativeElement.remove();
    }
  }
}
