<div class="px-4 py-5">
    <h3 class="mb-4">Contact Us</h3>
    <div [ngSwitch]="currentMfrProgram">
        <ng-container *ngSwitchCase="'CNH'">
            <!-- CNH -->
            <div id="cnh-us-contacts">
                <img src="assets/images/us.svg" class="me-2" style="margin-top: -6px; width: 24px;">
                <h4 class="d-inline">US Dealers</h4>
                <p class="pt-3 fw-bolder">PPP Quote and Registration Support</p>
                <p>Phone: 800-296-1043</p>
                <!-- <p>Email: <a [href]="mailto('pppsalesus@cnhinsurance.com')"
                        target="_blank">pppsalesus@cnhinsurance.com</a></p> -->
                <p class="pt-2 fw-bolder">PPP Claims Support</p>
                <p>Phone: 800-296-1220</p>
                <!-- <p>Email: <a [href]="mailto('pppclaims@cnhinsurance.com')"
                        target="_blank">pppclaims@cnhinsurance.com</a></p> -->
            </div>

            <div id="cnh-canada-contacts" class="pt-4">
                <img src="assets/images/ca.svg" class="me-2" style="margin-top: -6px; width: 24px;">
                <h4 class="d-inline">Canadian Dealers</h4>
                <p class="pt-3 fw-bolder">PPP Quote and Registration Support</p>
                <p>Phone: 800-296-1064</p>
                <!-- <p>Email: <a [href]="mailto('pppsalescn@cnhinsurance.com')"
                        target="_blank">pppsalescn@cnhinsurance.com</a></p> -->
                <p class="pt-2 fw-bolder">PPP Claims Support</p>
                <p>Phone: 800-296-1220</p>
                <!-- <p>Email: <a [href]="mailto('pppclaims@cnhinsurance.com')"
                        target="_blank">pppclaims@cnhinsurance.com</a></p> -->
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
             <!-- EPG -->
            <div id="epg-us-contacts">
                <img src="assets/images/us.svg" class="me-2" style="margin-top: -6px; width: 24px;">
                <h5 class="d-inline">United States</h5>
                <p class="pt-3">Phone: 866-408-2881</p>
                <p>Quote and Registrations Email: <a [href]="mailto('sales@epgins.com')"
                        target="_blank">sales@epgins.com</a></p>
                <p>Claims Email: <a [href]="mailto('esppclaims@epgins.com')" target="_blank">esppclaims@epgins.com</a>
                </p>
            </div>
        </ng-container>
    </div>
</div>