<form method="post" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <!-- <div class="login-form">
        <div class="d-flex justify-content-center">
            <div class="mb-3 text-center">
                <div class="brand-login mb-4"></div>
                <h3>ESPP Login</h3>
            </div>
        </div>
        <div class="form-floating mb-3">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email" autocomplete="off" />
                <mat-icon matSuffix>alternate_email</mat-icon>
            </mat-form-field>
        </div>
        <div class="form-floating mb-3">
            <mat-form-field class="example-full-width mb-0" appearance="outline">
                <mat-label>Password</mat-label>
                <input [type]="hide ? 'password' : 'text'" type="text" formControlName="password" matInput autocomplete="off" />
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off'
                    : 'visibility'}}</mat-icon>
            </mat-form-field>
        </div>
        <div class="d-grid">
            <button type="submit" mat-flat-button color="primary" class="mt-2 mb-2" [class.spinner]="loading" (click)="spin()" [disabled]="!loginForm.valid">Log In</button>
        </div>
        <mat-error *ngIf="errorMessage" class="lh-1 mt-3"><small>Login failed. Check your email and
                password and try again.</small>
        </mat-error> -->
        <!-- <div class="text-center pt-4"> -->
            <!-- <p class="text-black-50 mb-0">Don't have account? <a [routerLink]="['/register']">Register now.</a></p> -->
            <!-- <button mat-stroked-button class="rm-light me-2" routerLink="/register">Register Here</button>
            <button mat-stroked-button class="rm-light" routerLink="./forgot_password">Reset Password</button>
        </div> -->
        <!-- <div class="text-center pt-3">
            <p class="text-black-50">Forgot password? <a [routerLink]="['./forgot_password']">Click Here.</a></p>
        </div> -->
    <!-- </div> -->
</form>