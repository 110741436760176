import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class ChatMessageService {

  resultMessage: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private http: HttpClient) { }

  sendMessage(message: string){
    return this.http.post<object>(`api/v1/messages`,{message})
    .pipe(
      catchError(
        errorResponse => {
          const errorCode = errorResponse.status;
          switch (errorCode) {
            case 422:
              return throwError('The message requires a requires a length between 5 and 255 characters.');
            default:
              return throwError('You have incountered an error please contact support.');
          }
        }
      ),
      switchMap( result => {
        console.log(result)
        return of('test')
      })
    )
  }
}
