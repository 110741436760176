
import { Component, Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EpgDialogComponent } from './epg-dialog.component';

@Injectable()
export class EpgDialogService {

  constructor(private modalService: MatDialog) { }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string | null = null,
    dialogSize: 'sm'| 'md' |'lg' = 'sm'): MatDialogRef<EpgDialogComponent> {

    const modalRef = this.modalService.open(EpgDialogComponent, {
        hasBackdrop: true,
        disableClose: true,
        data: {
            title,
            message,
            btnOkText,
            btnCancelText,
            dialogSize
        }
    });

    return modalRef;
  }

  public open(component: Type<Component>) {
    const modalRef = this.modalService.open(component, {
        hasBackdrop: true,
        disableClose: true,
        data: {}
    });

    return modalRef;
  }

}
