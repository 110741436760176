import { HttpErrorResponse } from "@angular/common/http";

export interface ApiErrorParserInterface {
    parseError(errors: ApiError): void
 }

interface ApiErrorInterface {
    getCode(): number;
}

 export class ApiError implements ApiErrorInterface{
    public status: number;
    public message: string;
    public messages: ValidationErrors;

    constructor(status: number, message: string, messages: ValidationErrors){
        this.status =  status;
        this.messages = messages;
        this.message = message;
    }

    getCode(): number {
        return this.status;
    }
}

export class ApiErrorFactory {
    private errorResponse: HttpErrorResponse;
    constructor(errorResponse: HttpErrorResponse){
        this.errorResponse = errorResponse;
    }

    public make(): ApiError {
        return new ApiError(this.errorResponse.status,this.errorResponse.error.message,this.errorResponse.error.errors);
    }
}

type ValidationErrors = {[key: string]: Array<string>}