import { Observable } from "rxjs";

export interface DownloadPDFOptions {
    label: Observable<string | any> | string,
    url: string
}

export enum PDFLang {
    en = "English",
    es = "Spanish",
    fr = "French"
}
