import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { QuoteTypeService } from '../../services/quote-type/quote-type.service';

@Injectable()
export class QuoteTypeResolver implements Resolve<{[key: string]: string}[]> {
    constructor(private quoteTypeService: QuoteTypeService){}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{[key: string]: string}[]> {
    return this.quoteTypeService.getQuoteTypes();
  }
}
