type Permission = {
    name: string;
}

type MfrProgram = {
    id: number;
    name: string;
    current: boolean,
    active: boolean,
    dealer_id: number
}

type ViewLevel = 'Corporation' | 'Dealership' | 'User'

export class User {
    constructor(
        public id: number,
        public first_name: string,
        public last_name: string,
        public email: string,
        public permissions: Permission[],
        public mfr_programs: MfrProgram[],
        public view_level?: ViewLevel,
        public password_expiration?: Date | null,
        public locale: string  = 'en-US',
        public staff:boolean = false,
    ){}
}

