/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BaseUrlInterceptor } from './base-url.interceptor';
import { TokenInterceptor } from './token.interceptor';
import { UnauthorizedInterceptor } from './unauthorized.interceptor';
import { ApiErrorInterceptor } from './api-error.interceptor';
import { MockedDataInterceptor } from './mocked-data.interceptor';


/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MockedDataInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
];
