import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Dealership, Salesperson } from './dealer.service.types';
import {
  DEALERSHIP_LIST,
  SALESPERSON_LIST,
} from 'src/app/shared/api.constants';
@Injectable()
export class DealerService {
  /**
   * Creates an instance of Dealer service
   */
  constructor(private http: HttpClient) {}
  /**
   * get Dealers
   * @param filter
   * @returns
   */
  public getDealers(filter: string | number): Observable<Dealership[]> {
    let params = new HttpParams();
    params = params.append('filter', filter);
    return this.http.get<Dealership[]>(DEALERSHIP_LIST, { params });
  }
  /**
   * get Salesperson
   * @param filter
   * @returns
   */
  public getSalesperson(
    dealershipId: string | number,
    filter: string | number
  ): Observable<Salesperson[]> {
    let params = new HttpParams();
    params = params.append('filter', filter);
    return this.http.get<Salesperson[]>(`${SALESPERSON_LIST}${dealershipId}`, {
      params,
    });
  }
}
