/**
 * Snackbar Message type
 */
export enum MessageType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}
/**
 * Custom Snackbar options
 */
export interface openSnackbarOptions {
  message: string;
  duration?: number; // milliseconds
  type?: MessageType;
}
/**
 * CSS class names
 */
export enum CssClass {
    SUCCESS = 'snack-success',
    ERROR = 'snack-error',
    WARNING = 'snack-warning',
    INFO = 'snack-info',
  }
