import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
export type Suggestion = {
    term: string,
    count: number
}

@Injectable({
    providedIn: 'root'
})
export class SearchSuggestinsService {

    constructor(private http: HttpClient) { }

    public getSuggestions(term: string): Observable<Suggestion[]> {
        let params = new HttpParams();
        params = params.append('term', term)
        return this.http.get<Suggestion[]>('api/v1/search/suggestions', { params })
    }
}
