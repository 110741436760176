<div class="new-claim-modal pb-4">
    <div class="container-fluid">
        <div class="col p-0 pb-2">
            <h1>Start Claim</h1>
        </div>
        <form [formGroup]="claimForm" (submit)="$event.preventDefault()">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Serial Number</mat-label>
                <input matInput placeholder="Serial Number" formControlName="serial_number" (keydown.enter)="search($event)">
                <button type="button" *ngIf="claimForm.controls.serial_number.value" matSuffix mat-icon-button
                    aria-label="Clear" (click)="clearInput()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <p *ngIf="searchedCoverages.length == 0 && searchedEquipment; else coverageList" class="text-danger small">
                There are no current plans found for the serial number provided. Please check the number and try again, or
                contact the program administrator for assistance.
            </p>
            <p *ngIf="errorMessage" class="text-danger small">
                {{errorMessage}}
            </p>
            <div class="col pb-3">
                <button type="button" mat-flat-button color="primary" class="float-end"
                    [disabled]="!claimForm.valid || submitting || searching" (click)="search($event)"
                    [class.spinner]="searching">Search</button>
            </div>

            <!-- LOOP FOR UNITS WITH MULTIPLE RECORDS FOUND -->
            <ng-template #coverageList>
                <div class="row" *ngFor="let policy of searchedCoverages">
                    <div class="col-auto">
                        <button type="button" mat-flat-button color="primary" [disabled]="submitting"
                            [class.spinner]="spin && claimCoverageID == policy.id" (click)="openClaim(policy.id)">Open
                            Claim</button>
                    </div>
                    <div class="col">
                        <p class="small m-0"><span class="fw-bolder">Serial Number:</span> {{policy.serial_number}}</p>
                        <p class="small m-0"><span class="fw-bolder">Plan Number:</span> {{policy.policy_number}}</p>
                        <p class="small m-0"><span class="fw-bolder">Equipment:</span> {{policy?.equipiment?.year}}
                            {{policy?.equipiment?.manufacturer}} {{policy?.equipiment?.model}}
                            {{policy?.equipiment?.equipment_type}}</p>
                        <ng-container *ngFor="let coverage of policy.coverages">
                            <p class="small m-0"><span class="fw-bolder">Coverage:</span> {{coverage.coverage}}</p>
                            <p class="small m-0"><span class="fw-bolder">Coverage Period:</span> {{coverage.term}} / {{coverageHours(coverage.hours)}}</p>
                            <p class="small m-0"><span class="fw-bolder">Effective Dates:</span> {{coverage.effective_date | date}} - {{coverage.expiration_date | date}}</p>
                        </ng-container>
                        <!-- <p class="small m-0"><span class="fw-bolder">Coverage:</span> {{coverage.coverage}}</p>
                        <p class="small m-0"><span class="fw-bolder">Coverage Effective Date:</span>
                            {{coverage.formatted_dates.effective_date}}</p> -->
                    </div>
                    <div class="col-100">
                        <hr class="my-3">
                    </div>
                </div>
            </ng-template>
            <!-- BUTTON FOR UNITS WITH A SINGLE RECORD FOUND -->
            <!-- <button type="submit" mat-flat-button color="primary" [disabled]="!claimForm.valid || !searchedEquipment" *ngIf="coverages.length > 0">Open Claim</button> -->
        </form>
    </div>
</div>