import { BrowserModule } from '@angular/platform-browser';
import {  NgModule } from '@angular/core';

import { OktaModule } from '../../okta.module';
import { LoginFormComponent } from './login-form.component';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
@NgModule({
    declarations: [LoginFormComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatIconModule,
        OktaModule
    ],
    exports: [LoginFormComponent]
})
export class LoginFormModule { }
//https://medium.com/dailyjs/dynamic-locales-in-angular-dd9a527ebe1f