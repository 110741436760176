/**
 * @enum Permission
 */
export enum Permission {
  // Special permission to enable any route
  ALL = 'ALL',
  // Permissions v2
  APPROVE_MPS = 'APPROVE_MPS',
  CREATE_CLAIM = 'CREATE_CLAIM',
  CREATE_MPS = 'CREATE_MPS',
  CREATE_QUOTE = 'CREATE_QUOTE',
  DELETE_CLAIM = 'DELETE_CLAIM',
  DELETE_ENROLLED_UNIT = 'DELETE_ENROLLED_UNIT',
  DELETE_MPS = 'DELETE_MPS',
  EDIT_CLAIM = 'EDIT_CLAIM',
  EDIT_ENROLLED_UNIT = 'EDIT_ENROLLED_UNIT',
  EDIT_QUOTE = 'EDIT_QUOTE',
  QUOTE_CONTACT_AUTO_FILL = 'QUOTE_CONTACT_AUTO_FILL',
  READ_CLAIM = 'READ_CLAIM',
  READ_QUOTE = 'READ_QUOTE',
  REPLICATE_MPS = 'REPLICATE_MPS',
  SEARCH_CLAIMS = 'SEARCH_CLAIMS',
  SEARCH_CORPORATION_ENROLLED_UNITS = 'SEARCH_CORPORATION_ENROLLED_UNITS',
  SEARCH_DRAFT_CLAIMS = 'SEARCH_DRAFT_CLAIMS',
  SEARCH_ENROLLED_UNITS = 'SEARCH_ENROLLED_UNITS',
  SEARCH_QUOTES = 'SEARCH_QUOTES',
  SELF_APPROVE_MPS = 'SELF_APPROVE_MPS',
  UPDATE_MPS = 'UPDATE_MPS',
  UPDATE_CLAIM = 'UPDATE_CLAIM',
  UPDATE_QUOTE = 'UPDATE_QUOTE',
  VIEW_CLAIM = 'VIEW_CLAIM',
  VIEW_CLAIMS = 'VIEW_CLAIMS',
  VIEW_CORPORATION_CLAIMS = 'VIEW_CORPORATION_CLAIMS',
  VIEW_DEALERSHIP_CLAIMS = 'VIEW_DEALERSHIP_CLAIMS',
  VIEW_ENROLLED_UNIT = 'VIEW_ENROLLED_UNIT',
  VIEW_ENROLLED_UNITS = 'VIEW_ENROLLED_UNITS',
  VIEW_MPS = 'VIEW_MPS',
  VIEW_NOTIFICATIONS = 'VIEW_NOTIFICATIONS',
  VIEW_PERMISSIONS = 'VIEW_PERMISSIONS',
  VIEW_QUOTE = 'VIEW_QUOTE',
  VIEW_QUOTES = 'VIEW_QUOTES',
  VIEW_ROLES = 'VIEW_ROLES',
  APPLY_FOR_COVERAGE = 'APPLY_FOR_COVERAGE',
  // Permissions v1
  CREATE_USER = 'CREATE_USER',
  VIEW_USER = 'VIEW_USER',
  UPDATE_USER = 'UPDATE_USER',
  DEACTIVATE_USER = 'DEACTIVATE_USER',
  CREATE_DEALER = 'CREATE_DEALER',
  VIEW_DEALER = 'VIEW_DEALER',
  UPDATE_DEALER = 'UPDATE_DEALER',
  DEACTIVATE_DEALER = 'DEACTIVATE_DEALER',
  INVITE_USER = 'INVITE_USER',
  DELETE_QUOTE = 'DELETE_QUOTE',
  READ_POLICY = 'READ_POLICY',
  DEALERSHIP_SELECTION = 'DEALERSHIP_SELECTION',
  DEALERSHIP_FILTER = 'DEALERSHIP_FILTER',
  PRESALE_UNITS = 'PRESALE_UNITS'
}
