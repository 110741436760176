// Login
export const LOGIN = 'api/login';
export const APP_LOGIN = 'api/v2/user/login';
// Refresh token
export const REFRESH_TOKEN = 'login/okta/refresh';
// Password Reset
export const PASSWORD_RESET = 'api/v2/reset-password';
// User Login
export const USER_LOGIN = 'api/v2/user/login';
// SSO Login
export const SSO_LOGIN = 'api/login/sso';
// Logout
export const LOGOUT = `api/v2/user/logout`;
// User Info
export const USER_INFO = 'api/v1/user_info';
// Request Access
export const REQUEST_ACCESS = 'api/v2/request_access';
// Forgot Password
export const FORGOT_PASSWORD = 'api/v2/forgot-password';
// User
export const USER = 'api/v1/user';
// Register Email
export const REGISTER_EMAIL = 'api/v2/register/emails'
// Dealership list
export const DEALERSHIP_LIST = 'api/v2/dealership/search/';
// Salesperson by dealership
export const SALESPERSON_LIST = 'api/v2/user/search-by-dealership/';
// Serial Number API
export const SERIAL_NUMBER = 'api/v2/equipment';
