import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EsppQuoterComponent } from 'src/app/modules/espp/modules/espp-quoter/espp-quoter.component';
import { QuoteService } from '../quote.service';

@Injectable({  providedIn: 'root'})
export class QuoteFormService {

    public modalMessage = new BehaviorSubject<string[]>([]);

    constructor(public dialog: MatDialog, private quoteService: QuoteService) { }

    public showQuoteForm(quoteID: number | null = null, message: string |  null = null, showMesages: boolean = false){
        let dialogRef;
        if(!quoteID){
            dialogRef = this.dialog.open(EsppQuoterComponent, { 
                disableClose: true,
                panelClass:'quote-dialog',
               });
               return
        }
        if(showMesages){
            this.modalMessage.next(["All date fields must be populated in order to apply for coverage. Please complete the fields in order to update your quote and proceed to the application."])
        }
        console.log(showMesages,this.modalMessage.value)
        this.quoteService.getQuote(quoteID)
            .pipe(
                tap( quote => {
                    dialogRef = this.dialog.open(EsppQuoterComponent, { 
                        disableClose: true,
                        panelClass:'quote-dialog',
                        data: quote
                       });

                    //    dialogRef.afterClosed().subscribe(result => {
                    //     console.log('close')
                    //     this.modalMessage.next([])
                    //   });
                })
            )
            .subscribe(quote => {
                // this.dialog.open(EsppQuoterComponent, { 
                //     disableClose: true,
                //     data: quote
                //    });
            })
    }

    public closeQuoteForm(){
        this.modalMessage.next([])
        this.dialog.closeAll();
    }
}
