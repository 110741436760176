import { LocalStorage } from "../Storage";

export enum AppSetting {
    TTL = 'ttl',
    Headers = 'headers',
    APP_PROCESSING_TIME = 'app_processing_estimate',
    Quote_Configuration = 'quoting_configuration'
}

export class AppSettingStore extends LocalStorage<string> {
    private storageKey = 'APP_Setting';

    private static instance: AppSettingStore;

    private constructor() { super() }

    public static getInstance(): AppSettingStore {
        if (!AppSettingStore.instance) {
            AppSettingStore.instance = new AppSettingStore();
        }

        return AppSettingStore.instance;
    }
    
    private get settings(): object {
        return JSON.parse(super.get(this.storageKey) ?? '{}');
    }

    /**
     * store
     */
    public store(key: AppSetting, value: string | number) {
        const settings = this.settings;
        settings[key] = value;
        this.set(this.storageKey,JSON.stringify(settings))
        return value;
    }

    /**
     * get
     */
    public get(key: AppSetting): string {
        return this.settings[key];
    }

    get headers(){
        return JSON.parse(this.get(AppSetting.Headers))
    }
}