import { DatePipe, getLocaleId } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortDate',
})
export class ShortDatePipe implements PipeTransform {

    constructor(private datePipe: DatePipe,@Inject(LOCALE_ID) private locale: string) { }

    transform(value: Date): string | null{
        // var language: string = this.translateService.getBrowserCultureLang();
        // console.log(language);
        // return (value == "" || value == null) ? "" : formatDate(value, 'shortDate', language)
       // console.log(this.datePipe.transform(value,undefined,undefined,"en-fr"))
        return this.datePipe.transform(value,'shortDate',undefined,"en-NZ");
    }

}
