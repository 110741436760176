import { Inject, Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app-config.service';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

    urlsToNotUse: Array<string>;

    constructor(@Inject('ESPP_API_URL') private baseUrl: string,private appConfig: AppConfigService) {
        this.urlsToNotUse = [
            'assets/config/.+',
        ];
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        //console.log(this.appConfig.esppBaseApi)
        if (request.url.includes('assets/config/')) return next.handle(request);
        const apiReq = request.clone({ url: `${this.baseUrl}/${request.url}`, withCredentials: true, setHeaders: { 'Request-Client-Orgin': window.location.pathname } });
        return next.handle(apiReq);
    }
}
