import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
type Manufacturer = { manufacturer: string, rating_type: string }
export type Term = { 
    warranty_text: string,
    warranties: {
        warranty_rank: number
        hours: number
        term: number
        coverage: string
    }[]
 }
@Injectable()
export class ManufacturerService {

    constructor(private http: HttpClient) { }

    public getManufacturers(quoteType: string,saleType: string, dealershipId?: string): Observable<Manufacturer[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('quote_type', quoteType);
        params = params.append('sale_type', saleType);
        if (dealershipId) params = params.append('dealership_id', dealershipId || '');
        return this.http.get<Manufacturer[]>('api/v1/espp/manufacturers', { params })
    }

    public getWarrantyTerms(quoteType: string,program: string,manufacturer: string, model: string, equipType: string,ratingType:string): Observable<Term[]> {
        // return of([
        //     { name: 'Volvo' }
        // ])
        let params: HttpParams = new HttpParams();
        params = params.append('program', program);
        params = params.append('quote_type', quoteType);
        params = params.append('manufacturer', manufacturer);
        params = params.append('model', model);
        params = params.append('equip_type', equipType);
        params = params.append('rating_type', ratingType);
        return this.http.get<Term[]>('api/v1/espp/warranty_terms', { params })
    }
}
