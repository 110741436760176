import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HostInformation } from './system-info.types';
/**
 * System information service
 */
@Injectable({
  providedIn: 'root',
})
export class SystemInfoService {
  constructor() {}
  /**
   * @returns HostInformation
   * @memberof SystemInfoService
   */
  private get hostInformation(): HostInformation {
    const host = window.location.hostname;
    let hostInfo: HostInformation;
    switch (host) {
      case 'ppp.cnhinsurance.com':
      case 'dev-espp.epgins.com':
        hostInfo = { country: 'CA', host };
        break;
      default:
        hostInfo = { country: 'US', host };
        break;
    }
    return hostInfo;
  }
  /**
   * @returns boolean
   * @memberof SystemInfoService
   */
  public get isCNH(): boolean {
    const sysInfo = this.hostInformation;
    if (sysInfo) {
      return [sysInfo.host].some((host) =>
        ['ppp.cnhinsurance.com', 'espp-integration-model.epgins.com'].includes(
          host
        )
      );
    }
    return false;
  }
}
