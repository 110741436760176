import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[errorMessage]',
  host: {
    // These are like ngClass class condition values
    '[class.text-danger]': 'true', // Default class for example
  },
})
export class ErrorMessageDirective {
    constructor() {}
}
