import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { snackbarOptions } from './toast.types';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
})
export class ToastComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: snackbarOptions) {}

  public get type() {
    return this.data.type
  }
}
