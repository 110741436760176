import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, exhaustMap, filter, switchMap } from 'rxjs/operators';
import { Equipment } from 'src/app/core/types/equipment';
import { ClaimService } from 'src/app/shared/services/claim/claim.service';
import { Coverage, EnrolledUnit } from 'src/app/shared/services/enrolled-unit.service';
import { EquipmentStatusService } from '../../../modules/espp-quoter/services/equipment-status/equipment-status.service';

type CoverageData = 
{
    id: number,
    serial_number: string,
    policy_number: string,
    equipiment: Pick<Equipment, 'year' | 'manufacturer' | 'model' | 'equipment_type' | 'serial_number'>,
    coverages: Pick<Coverage, 'term' | 'hours' | 'coverage' | 'coverage_type' | 'effective_date' | 'expiration_date'>[]
}

@Component({
    selector: 'app-new-claim-form',
    templateUrl: './new-claim-form.component.html',
    styleUrls: ['./new-claim-form.component.scss'],
    //changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewClaimFormComponent implements OnInit {

    public policies = new BehaviorSubject<EnrolledUnit[]>([]);
    public coverages: CoverageData[] = [];
    public searchedEquipment = false;
    public searching = false;

    public claimForm = new FormGroup({
        serial_number: new FormControl(null, [Validators.required, Validators.minLength(4)]),
        coverage_id: new FormControl(null, [])
    })

    constructor(
        private claimService: ClaimService, 
        private dialog: MatDialog, 
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any 
        ) { }

    public results = new Map<number, EnrolledUnit[]>();
    public serialNumber: string | null = null;
    public search(event) {
        this.searching = true;
        this.claimService.searchSerialNumber(this.claimForm.controls.serial_number.value)
            .pipe(
                catchError(error => {
                    return throwError('Could not search serial number.');
                }),
                switchMap(enrolledUnits => {
                    this.searchedEquipment = true;
                    //console.log(enrolledUnits)
                    return of(enrolledUnits.reduce((previousValue, currentValue: EnrolledUnit) => {
                        const policyNumber = currentValue.plan_number;
                        const serialNumber = currentValue.equipment[0].serial_number;
                        const equipiment: Pick<Equipment, 'year' | 'manufacturer' | 'model' | 'equipment_type'> = currentValue.equipment[0]
                        const coverages = currentValue.equipment[0].coverages
                            .filter(coverage => coverage.coverage_type != 'BASE');
                            console.log(policyNumber,serialNumber,equipiment,coverages)
                        return [
                             ...previousValue,
                             ...(coverages.length > 0? [
                                {
                                    id: currentValue.id,
                                    serial_number: serialNumber,
                                    policy_number: policyNumber,
                                    equipiment: equipiment,
                                    coverages: coverages
                                 }
                             ] : [])
                        
                        ]
                    }, [] as any[]));
                })
            )
            .subscribe(
                data => {
                    this.coverages = data
                    this.searching = false;
                },
                error => { this.searching = false; }
            )
    }
    //314672
    ngOnInit(): void {
        if(!this.data) return;
        const policyNumber = this.data.policyNumber
        if(policyNumber){
            this.claimForm.controls.serial_number.patchValue(policyNumber);
            this.claimForm.controls.serial_number.disable();
            this.search(null)
        }
     }

    clearInput() {
        this.claimForm.reset();
        this.coverages = [];
        this.searchedEquipment = false
    }

    public coverageHours(coverageHours: number): string | number {
        if(coverageHours === 9999) {
            return "Unlimited"
        }
        return coverageHours
    }

    public submitting = false;
    public errorMessage: string | null = null;
    public claimCoverageID = 0;
    openClaim(id: number) {
        this.submitting = true;
        this.claimCoverageID = id;

        //const id = +this.claimForm.controls.coverage_id.value;
        this.claimService.openClaim(id)
            .subscribe(
                claim => {
                    this.dialog.closeAll();
                    this.router.navigate(['apps', 'espp', 'claims', claim.id], { queryParams: { model_type: claim.model_type } })
                },
                errorResponse => {
                    this.submitting = false;
                    this.errorMessage = errorResponse;
                    this.claimCoverageID = 0;
                }
            )

    }

    public get searchedCoverages() {
        return this.coverages
    }

    get spin() {
        return this.submitting
    }
}