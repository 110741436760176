<mat-form-field class="example-full-width" appearance="outline">
  <mat-label>{{options?.labelText}}<span *ngIf="isRequired">*</span></mat-label>
  <input
    type="text"
    matInput
    [matAutocomplete]="auto"
    [id]="id"
    [value]="value"
    (change)="onChange($any($event).target.value)"
    (blur)="onTouched()"
    [formControl]="$any(ngControl).control"
    [placeholder]="options?.placeholder || ''"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="optionSelectedValue($any($event).option.value)"
  >
    <mat-option
      *ngFor="let option of (options.list | async)"
      [value]="option[options.optionValue]"
    >
      {{ option[options.optionLabel] }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="ngControl.control?.hasError('required')">
    This field is required
  </mat-error>
  <mat-error *ngIf="ngControl.control?.hasError('maxlength')">
    Maximum {{ $any(ngControl).control.errors['maxlength'].requiredLength }} characters are allowed
  </mat-error>
  <mat-error *ngIf="ngControl.control?.hasError('minlength')">
    Minimum {{ $any(ngControl).control.errors['minlength'].requiredLength }} characters are required
  </mat-error>
  <mat-error *ngIf="ngControl.control?.hasError('pattern')">
    Invalid format
  </mat-error>
</mat-form-field>
