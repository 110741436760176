import { APP_INITIALIZER, NgModule } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";


import { environment } from 'src/environments/environment';
import { MaterialModule } from "../shared/material.module";
import { ErrorReporterComponent } from "./components/error-reporter/error-reporter.component";
import { httpInterceptorProviders } from "./interceptors";
import { ApiErrorService } from "./services/api-error-service/api-error-service";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BackButtonDirective } from './directives/back-button/back-button.directive';
import { AppConfigService } from "../app-config.service";
import { ToastNotificationComponent } from './components/toast-notification/toast-notification.component';
import { MatIconModule } from "@angular/material/icon";
import { AppDataService } from "./app-data/app-data.service";
import { ToastNotificationService } from "../shared/services/toast-notification/toast-notification.service";
import { ToastComponent } from "../shared/services/toast-notification/toast-component/toast.component";

@NgModule({
    declarations: [ErrorReporterComponent, ToastNotificationComponent],
    imports: [
        MatSnackBarModule,
        MatIconModule
    ],
    providers: [
        { provide: "ESPP_API_URL", useValue: environment.esppApiBase },
        httpInterceptorProviders,
        ApiErrorService,
        MatDialog,
        AppDataService,
        ToastNotificationService,
    ],
    exports: [ErrorReporterComponent]
})
export class CoreModule { }