import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

const passowrdValidator = (controlName: string, matchingControlName: string) => {
    return (formControl: AbstractControl): ValidationErrors | null => {
        const formGroup = formControl as FormGroup;
        let control = formGroup.controls[controlName];
        let matchingControl = formGroup.controls[matchingControlName]
        if (control.value !== matchingControl.value) {
            return { confirmPasswordValidator: true };
        } else {
            return null;
        }
    };
}

@Component({
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    public resetPasswordForm = new FormGroup({
        email: new FormControl(null,[Validators.required,Validators.email]),
        password: new FormControl(null,[Validators.required, Validators.min(8)]),
        password_confirmation: new FormControl(null,[Validators.required, Validators.min(8)]),
        token: new FormControl(null,[Validators.required])
    }, {
        validators: passowrdValidator("password", "password_confirmation")
    })

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
      this.activatedRoute.queryParams
      .subscribe(
          data => this.resetPasswordForm.patchValue({email: data['email']})
      )

      this.activatedRoute.params
      .subscribe(
          data => this.resetPasswordForm.patchValue({token: data['token']})
      )
  }

  onSubmit(){
        this.http.patch<any>('api/v1/password/reset',this.resetPasswordForm.value)
            .subscribe(
                d => this.router.navigate(['login'])
            )
  }

}
