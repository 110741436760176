import { Component, ComponentFactoryResolver, HostBinding, Input, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { BaseComponent } from '../base/base.component';

@Component({
    template: ''
})
export class FormControlComponent extends BaseComponent implements ControlValueAccessor {
    @HostBinding('attr.id')
    public externalId: string | null = '';

    private _id = '';
    @Input() public set id(value: string) {
        this._id = value;
        this.externalId = null;
    }
    public get id() {
        return this._id;
    }

    private _disabled: boolean | null = null;
    @Input() public get disabled() {
        return this._disabled;
    }

    /**
     * "any" type needed by reusable components which store Array values
     */
    private _value: any = null
    @Input() get value() {
        return this._value;
    }

    constructor(@Self() public ngControl: NgControl, public resolver: ComponentFactoryResolver) {
        super();
        if(this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public onChange: any = () => {};

    public onTouched: any = () => {};

    public writeValue(val: string) {
        if(val) {
            this._value = val;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this._disabled = isDisabled;
    }
}