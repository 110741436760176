import { HttpClient, HttpErrorResponse, HttpEventType, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiError } from 'src/app/core/api-errors/ApiError';
import { Equipment } from 'src/app/core/types/equipment';
import { ClaimQueueItem, ClaimsResponse } from 'src/app/core/types/responses/ClaimsResponse';
import { generateErrorMessage, HttpStatusCodeRange, HttpStatusCodeRangeUtil } from 'src/app/core/utils/HttpStatusCodeRange';
import { FileUploadFormComponent } from 'src/app/modules/espp/claims/components/file-upload-form/file-upload-form.component';
import { DebugPanel } from '../../components/debug-panel/DebugPanel';
import { EpgDialogService } from '../../components/epg-dialog/epg-dialog.service';
import { EnrolledUnit, EnrolledUnitQueueResponse } from '../enrolled-unit.service';
import { RepairingDealerAddresssService } from './repairing-dealer-address.service';
import { error } from 'console';
export type Story = { story: string, story_key: 'complaint' | 'cause' | 'correction' | 'complications' | 'special_instructions' }
type CurrencyCode = 'USD'
export type NumberSet = { submitted: number, approved: number }
export type Part = {
    id: number
    part_number: string
    part_description: string
    // part_price: number
    // part_quantity: number
    primary: boolean
    pricing: NumberSet
    quantity: NumberSet,
    approved: boolean
}
type Invoice = string | null;
export type Labor = {
    id: number
    labor_key: LaborKey
    hours: NumberSet
    rate: NumberSet
    invoice: Invoice
}
export type Customer = { id: number; name: string; };
export type PolicyCoverage = { id: number, effective_date: Date, expiration_date: Date, coverage: string, deductible: number, term: number, hours: number, use_type: string, comments: string | null };
export type Policy = {
    id: number
    plan_number: string,
    customer: Customer,
    equipment: {
        coverages: PolicyCoverage[]
        hours: number
        serial_number: string
        manufacturer: string
        equipment_type: string
        model: string
        equipment_usage: string
        year: number
    }[]
}
type Dealership = {
    id: number,
    phone: string
    company: string
    address: string
    city: string
    state: string
    zip: string
    country_code: string
    iso_country_code: string
}
type User = {
    id: number
    email: string
    first_name: string
    last_name: string
    phone_number: string
}
export enum ClaimStatus {
    DRAFT = 'DRAFT',
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    PAID = 'PAID',
}
export enum LaborKey {
    SHOP = 'SHOP',
    OUTSIDE = 'OUTSIDE',
}
export type StoryObject = {
    complaint: string
    cause: string
    correction: string
    complications: string
    special_instructions: string
}
export enum FileCategory {
    GENERAL = 'GENERAL',
    SUBMITTED_CLAIM = 'SUBMITTED_CLAIM',
    FINAL_CLAIM = 'FINAL_CLAIM',
    OUTSIDE_INVOICE = 'OUTSIDE_INVOICE'
}
export type File = {
    id: number
    name: string
    url: string
    category: FileCategory
    created_at: Date
}
type TTM = {
    travel_time: NumberSet
    travel_rate: NumberSet
    mileage_rate: NumberSet
    mileage: NumberSet
}
export interface Check {
    id: number
    check_number: string
    check_date: Date
    approved_amount: number
    declined_amount: number
}
export type Claim = {
    id: number
    status: ClaimStatus
    claim_number: string
    purchase_order: string
    repair_date: Date
    fail_date: Date
    currency: CurrencyCode
    failure_hours: number
    model_type: string
    parts: Part[]
    labor: Labor[],
    policy: Policy,
    coverage_id: number,
    dealership: Dealership,
    user: User
    story: StoryObject
    files: File[]
    ttm: TTM | null,
    has_ttm_claim: true,
    submitted_by: { email: string, phone_number: string, name: string }
    remarks: string | null,
    check: Check | null,
    policy_number: string,
    outside_invoice: NumberSet,
    tax_amount: NumberSet,
    service_fee: number | null,
    total_amount_approved: NumberSet,
    status_parts: {
        broom_status: string | null
        web_status: string | null
        status: string | null
        sub_status: string | null
    },
    repairing_dealer_address: RepairingAddress | null,
    monetary_viewable: boolean
}
type CurrentClaim = Omit<Claim, 'labor' | 'files' | 'parts'>
export type RepairingAddress = {
    id: number | null,
    address: string
    city: string
    state: string
    zip: string
}
export type ClaimSection = 'File' | 'Claim';
export type ClaimErrorMap = string[];
export type ClaimError = Map<ClaimSection, ClaimErrorMap>;

interface HttpErrorInterface {
    message: string;
    status: number;

    generateErrorMessage(errorResponse: any): string;
}
class HttpError implements HttpErrorInterface {
    message: string;
    status: number;

    constructor(errorResponse) {
        this.message = this.generateErrorMessage(errorResponse);
        this.status = errorResponse.status;
    }

    generateErrorMessage(errorResponse: any): string {
        var errorMessage = '';
        const range: HttpStatusCodeRange = HttpStatusCodeRangeUtil.getRange(errorResponse.status);
        const baseError = errorResponse.error.message;
        const requestID = (errorResponse.headers.get('request-id')).split('-');
        switch (range) {
            case HttpStatusCodeRange.CLIENT_ERROR_RANGE:
                errorMessage = `${baseError}`;
                break;
            case HttpStatusCodeRange.SERVER_ERROR_RANGE:
                errorMessage = `${baseError} Please contact the program administrator at (866) 408-2881 for assistance. Error ID: ${requestID[0]}`;
                break;
            default:
                errorMessage = `${baseError}`;
                break;
        }
        return `Error: ${errorMessage}`;
    }

    public toString = (): string => {
        return this.message;
    }

}


@Injectable({ providedIn: "root" })
export class ClaimService implements DebugPanel {

    public claims: BehaviorSubject<ClaimQueueItem[]> = new BehaviorSubject<ClaimQueueItem[]>([]);
    public queuePage: BehaviorSubject<{ current: number, last: number }> = new BehaviorSubject<{ current: number, last: number }>({ current: 0, last: 0 });
    public policy: BehaviorSubject<EnrolledUnit | null> = new BehaviorSubject<EnrolledUnit | null>(null);
    public currentClaim: BehaviorSubject<Claim | null> = new BehaviorSubject<Claim | null>(null);
    public claimsErrors: Subject<ClaimError> = new Subject<ClaimError>();
    public parts: BehaviorSubject<Part[]> = new BehaviorSubject<Part[]>([]);
    public labor: BehaviorSubject<Labor[]> = new BehaviorSubject<Labor[]>([]);
    public files: BehaviorSubject<File[]> = new BehaviorSubject<File[]>([]);
    public isDetailsOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private modal: MatDialog, private epgDialog: EpgDialogService, private dealerRepairingAddressService: RepairingDealerAddresssService) { }

    debugableAttributes(): string[] {
        return ['currentClaim', 'parts', 'labor', 'files']
    }
    openDebugSheet(): void {
        throw new Error('Method not implemented.');
    }

    getClaims(cursor: string | null = '1', perPage: number = 10): Observable<ClaimsResponse> {
        let params = new HttpParams();
        params = params.append('per_page', perPage + '')
        if (cursor) {
            params = params.append('page', cursor);
        }
        return this.http.get<ClaimsResponse>(`api/v1/espp/claims`, { params })
            .pipe(
                catchError(error => {
                    const errorMessage: string = generateErrorMessage(error);
                    return throwError(errorMessage)
                }),
                tap(claims => {
                    let newClaims = [...this.claims.value, ...claims.data]
                    const unique = [...new Map(newClaims.map((item, key) => [item['id'], item])).values()]
                    this.claims.next(unique);
                    this.queuePage.next({
                        ...(claims.meta.current_page == 1 ? { last: claims.meta.last_page } : { last: this.queuePage.value.last }),
                        ...{ current: claims.meta.current_page }
                    });
                })
            )
    }

    public searchSerialNumber(term: string): Observable<EnrolledUnit[]> {
        return this.http.post<EnrolledUnitQueueResponse>('api/v1/espp/claims/serial/search', { term })
            .pipe(map(response => response.data))
    }

    public openClaim(id: number): Observable<ClaimQueueItem> {
        return this.http.post<ClaimQueueItem>('api/v1/espp/claims', { equip_id: id })
            .pipe(
                catchError(error => {
                    return throwError(generateErrorMessage(error))
                }),
                tap(claim => {
                    const claimsQueue = this.claims.value;
                    console.log(claim)
                    // const newClaim = {
                    //     id: claim.id,
                    //     status: claim.status,
                    //     claim_number: claim.claim_number,
                    //     model_type: claim.model_type,
                    //     customer: claim.policy?.customer.name,
                    //     serial_number: claim.policy?.equipment[0].serial_number ?? 'NA',
                    //     file_count: 0,
                    //     outside_labor: claim.outside_invoice,
                    //     submitted_pdf_url:  null,
                    //     final_pdf_url: null,
                    //     dealership_id: claim.dealership.id,
                    //     po_number: claim.purchase_order
                    // }
                    this.claims.next([...[claim as ClaimQueueItem], ...claimsQueue])
                })
            )
    }

    public policyErrorMessage: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    public getCLaim(id: number, modelType: string): Observable<Claim> {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('search_term');
        let params = new HttpParams();
        params = params.append('model_type', modelType);
        params = params.append('search_term',myParam ?? '');
        return this.http.get<Claim>(`api/v1/espp/claims/${id}`, { params })
            .pipe(
                catchError(error => {
                    return throwError(error)
                }),
                tap(claim => {
                    if (typeof claim == 'string') return;
                    this.updateSubjects(claim);
                    this.parts.next(claim.parts);
                    this.labor.next(claim.labor);
                    this.files.next(claim.files);
                    this.currentClaim.next(claim);
                    if (claim.policy_number !== '') {
                        this.getEnrolledUnit(claim.policy_number)
                            .subscribe(d => {
                                if (!(d instanceof HttpError)) {
                                    this.policy.next(d);
                                } else {
                                    this.policyErrorMessage.next(d.message);
                                }

                            })
                    }
                    if(claim.monetary_viewable){
                        const finalPdf = claim.files.find(file => file.category === 'FINAL_CLAIM');
                        const submittedClaim = claim.files.find(file => file.category === 'SUBMITTED_CLAIM');
                        this.claims.next((this.claims.value).map(item => {
                            if (item.id === claim.id && finalPdf) {
                                item.final_pdf_url = finalPdf?.url;
                            }
                            if (item.id === claim.id && submittedClaim) {
                                item.submitted_pdf_url = submittedClaim?.url;
                            }
                            return item;
                        }));
                    }
                })
            )
    }

    //TODO refactor to exclude other subjcts from main claim subject
    private updateSubjects(claim: Claim): CurrentClaim {
        const { parts, labor, files, ...rest } = claim;
        // this.currentClaim.next(rest);
        // this.parts.next(claim.parts);
        // this.labor.next(claim.labor);
        // this.files.next(claim.files);
        return rest
    }

    //Refacter to enrolled unit serrvice
    public getEnrolledUnit(id: number | string): Observable<EnrolledUnit | HttpError> {
        return this.http.get<EnrolledUnit>(`api/v1/espp/enrolled_units/${id}`)
            .pipe(
                catchError(errorResponse => {
                    new HttpError(errorResponse)
                    // {message: generateErrorMessage(errorResponse), code: 11}
                    return of(new HttpError(errorResponse))
                })
            )
    }

    private updateClaimObservers(claim: Claim) {
        console.log(claim)
        this.currentClaim.next(claim);
        this.labor.next(claim.labor);
        const queue = this.claims.value
        this.claims.next(queue.map((queueItem, index) => {
            if (claim.id == queueItem.id) {
                const file = claim.files.find(file => file.category == 'SUBMITTED_CLAIM');
                const finalFile = claim.files.find(file => file.category == 'FINAL_CLAIM');
                return {
                    id: claim.id,
                    status: claim.status,
                    claim_number: claim.claim_number,
                    model_type: claim.model_type,
                    customer: claim.policy?.customer.name,
                    serial_number: claim.policy?.equipment[0].serial_number ?? 'NA',
                    file_count: claim.files.length,
                    outside_labor: queueItem.outside_labor,
                    submitted_pdf_url: file ? file.url : null,
                    final_pdf_url: finalFile ? finalFile.url : null,
                    dealership_id: claim.dealership.id,
                    po_number: claim.purchase_order
                }
            }
            return queueItem;
        }))
    }

    public autosave(id: number, body: object): Observable<Claim> {
        return this.http.patch<Claim>(`api/v1/espp/claims/${id}/autosave`, {
            ...body
        })
            .pipe(
                catchError(error => {
                    return throwError(generateErrorMessage(error))
                }),
                tap(
                    claim => {
                        const currentClaim = this.currentClaim.value;
                        if (currentClaim) {
                            currentClaim.ttm = claim.ttm;
                            currentClaim.outside_invoice = claim.outside_invoice;
                        }
                        const queue = (this.claims.value);
                        queue.forEach(item => {
                            if (item.id == id) {
                                item.outside_labor = claim.outside_invoice['submitted'];
                                item.po_number = claim.purchase_order;
                            }
                        })
                        this.claims.next(queue);
                        this.currentClaim.next(claim);
                        this.labor.next(claim.labor);
                        // this.parts.next(claim.parts)
                    }
                )
            )
    }

    public updateClaim(id: number, body: object): Observable<Claim> {
        return this.http.patch<Claim>(`api/v1/espp/claims/${id}`, {
            ...body
        })
            .pipe(
                tap(
                    claim => this.updateClaimObservers(claim)
                )
            )
    }

    public submitClaim(id: number, data = {}): Observable<Claim> {
        return this.http.patch<Claim>(`api/v1/espp/claims/${id}`, {
            ...data,
            ...{ Status: ClaimStatus.PENDING },
        })
            .pipe(
                tap(
                    claim => {
                        this.updateClaimObservers(claim);
                        if (claim.policy_number !== '') {
                            this.getEnrolledUnit(claim.policy_number)
                                .subscribe(d => {
                                    if (!(d instanceof HttpError)) {
                                        this.policy.next(d);
                                    }

                                })
                        }

                        const submittedClaim = claim.files.find(file => file.category === 'SUBMITTED_CLAIM');
                        this.claims.next((this.claims.value).map(item => {
                            if (item.id === claim.id && submittedClaim) {
                                item.submitted_pdf_url = submittedClaim?.url;
                            }
                            return item;
                        }))
                    }
                )
            )
    }

    public addPart(claimID: number, part: Omit<Part, 'id' | 'pricing' | 'quantity' | 'approved'>): Observable<Part> {
        return this.http.post<Part>(`api/v1/espp/claims/${claimID}/parts`, part)
            .pipe(
                tap(part => {
                    const claim = this.currentClaim.value;
                    if (claim) {
                        this.parts.next([
                            ...this.parts.value,
                            ...[part]])
                    }
                }),
                catchError(errorResponse => of(errorResponse))
            )
    }

    public updatePart(claimID: number, part: Part): Observable<Part> {
        return this.http.patch<Part>(`api/v1/espp/claims/${claimID}/parts/${part.id}`, part)
            .pipe(
                tap(part => {
                    const claim = this.currentClaim.value;
                    const parts = this.parts.value;
                    if (claim) {
                        this.parts.next(parts.map(currentPart => {
                            return currentPart.id == part.id ? part : currentPart;
                        }));
                    }
                }),
                catchError(errorResponse => of(errorResponse))
            )
    }

    public deletePart(claimID: number, partID: number): Observable<Omit<Part, 'pricing' | 'quantity'>> {
        return this.http.delete<Omit<Part, 'pricing' | 'quantity'>>(`api/v1/espp/claims/${claimID}/parts/${partID}`, {})
            .pipe(
                tap(part => {
                    const claim = Object.assign({}, this.currentClaim.value);
                    let parts = this.parts.value;
                    const partIndex = claim?.parts.findIndex(item => item.id == part.id);
                    parts.splice(partIndex, 1);
                    this.parts.next(parts);
                })
            )
    }

    public addLabor(claimID: number, labor: any[]) {
        return this.http.post<Labor[]>(`api/v1/espp/claims/${claimID}/labor`, { labor })
            .pipe(
                tap(labor => this.addLaborToClaim(labor))
            )
    }

    private addLaborToClaim(labor: any[]) {
        const claim = this.currentClaim.value;
        if (!labor || !claim) return;
        labor.forEach(labor => claim.labor.push(labor));
        this.currentClaim.next(claim)
    }

    private updateClaimLabor(laborID: number, updatedLabor: Labor) {
        const claim = this.currentClaim.value;
        const labor = claim?.labor;
        if (!labor || !claim) return;
        claim.labor = claim.labor.map((labor) => {
            if (labor.id == laborID) {
                return updatedLabor
            }
            return labor;
        })
        this.currentClaim.next(claim)
    }

    public updateLabor(claimID: number, laborID: number, labor: {
        hours: number | null
        rate: number | null,
        invoice: string | null
    }) {

        return this.http.patch<Labor>(`api/v1/espp/claims/${claimID}/labor/${laborID}`, {
            SubHrs: labor.hours,
            SubPrice: labor.rate,
            AppPrice: labor.rate,
            AppHrs: labor.hours,
            Invoice: labor.invoice
        })
            .pipe(
                tap(labor => this.updateClaimLabor(laborID, labor))
            )
    }

    //'compliant' | 'cause' | 'correction' | 'complications' | 'special_instructions'
    public updateStory(claimID: number, stories: Story[]) {
        return this.http.patch<any>(`api/v1/espp/claims/${claimID}/story`, { stories })
    }

    public openFileModal(id, outsideLaborIndex: number = -1) {
        this.modal.open(FileUploadFormComponent, { data: { id, outside_labor: outsideLaborIndex > -1 } })
    }

    public addFile(id: number, file, category: FileCategory, hasOutsideInvoice: '0' | '1' = '0'): Observable<File> {
        let formData = new FormData();
        formData.append('file_category', category);
        formData.append('has_outside_invoice', hasOutsideInvoice);
        //formData.append('files', file);
        for (var i = 0; i < file.length; i++) {
            console.log(file[i].size)
            formData.append("files[]", file[i]);
        }
        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
            observe: "events",
        };
        //@ts-ignore
        return this.http.post<ArrayBuffer>('api/v1/espp/claims/' + id + '/files', formData, options)
            .pipe(
                tap(data => {
                    if (data['type'] === HttpEventType.Response) {
                        const file = data['body'] as File[]
                        const claim = this.currentClaim.value
                        const files = this.files.value;
                        if (claim?.id == id) {
                            this.files.next([...files, ...file])
                        }
                        const newClaims = (this.claims.value).map(claim => {
                            if (claim.id == id) {
                                const fileCount = claim.file_count + file.length;
                                return {
                                    ...claim,
                                    ...{ file_count: fileCount }
                                }
                            }
                            return claim
                        })
                        this.claims.next(newClaims)
                    }
                })
            )
    }


    public deleteFile(claimID: number, fileID: number) {
        return this.http.delete<File>('api/v1/espp/claims/' + claimID + '/files/' + fileID)
            .pipe(
                catchError(error => {
                    return throwError(generateErrorMessage(error))
                }),
                tap(file => {
                    const fileID = file.id;
                    const queue = this.claims.value;
                    const claimItem = queue.find(item => item.id === claimID);
                    if (claimItem) {
                        this.claims.next(queue.map((item, index) => {
                            return {
                                ...item,
                                ...(claimID == claimItem.id ? { file_count: claimItem.file_count -= 1 } : {})
                            }
                        }));
                    }
                    const claim = this.currentClaim.value;
                    if (claimID == claim?.id) {
                        this.files.next((this.files.value).filter(currrentFile => file.id !== currrentFile.id));
                    }
                })
            )
    }

    public searchClaims(term: string, page: number = 1, perPage: number = 10) {
        return this.http.post<ClaimsResponse>('api/v1/espp/claims/search', { term, page, per_page: perPage })
            .pipe(
                // catchError(error => {
                //     return throwError(generateErrorMessage(error))
                // }),
                tap(claims => {
                    if (page == 1) {
                        this.claims.next([...claims.data]);
                        return;
                    }
                    this.claims.next([...this.claims.value, ...claims.data]);
                    // this.queuePage.next({
                    //     ...(claims.meta.current_page == 1? {last: claims.meta.last_page} : {last: this.queuePage.value.last}),
                    //     ...{current: claims.meta.current_page}
                    // });
                })
            )
    }

    public deleteClaim(id: number): Observable<Claim | null> {
        const queue = this.claims.value;
        const currentClaim = this.currentClaim.value;
        const queueItem = queue.find(claim => claim.id === id);
        if (queueItem) {
            if (queueItem.model_type.includes('Broom')) {
                return of(null);
            }
        }
        return this.http.delete<Claim>('api/v1/espp/claims/' + id)
            .pipe(
                catchError(error => {
                    const errorMessage: string = generateErrorMessage(error);
                    return throwError(errorMessage)
                }),
                tap(
                    claim => {
                        const queueIndex = queue.findIndex(item => item.id === claim.id);
                        queue.splice(queueIndex, 1);
                        this.claims.next(queue);
                        // if(currentClaim?.id === claim.id){
                        //     this.currentClaim.next(null);
                        // }
                    }
                )
            )
    }

    public repliateClaim(id: number): Observable<ClaimQueueItem> {
        return this.http.post<ClaimQueueItem>(`api/v1/espp/claims/${id}/replicate`, {})
            .pipe(
                tap(claim => {
                    const queue = this.claims.value;
                    this.claims.next([
                        ...[claim],
                        ...queue
                    ])
                })
            )
    }

    public createPaymentAddress(claimID: number, paymentData): Observable<RepairingAddress | ApiError> {
        return this.dealerRepairingAddressService.createRepairingAddress(claimID, paymentData)
            .pipe(
                tap(paymentDetails => this.updateClaimPaymentAddress(paymentDetails))
            );
    }

    public updatePaymentAddress(claimID: number, paymentAddressID: number, paymentData): Observable<RepairingAddress | ApiError> {
        return this.dealerRepairingAddressService.updateRepairingAddress(claimID, paymentAddressID, paymentData)
            .pipe(
                tap(paymentDetails => this.updateClaimPaymentAddress(paymentDetails))
            );
    }

    private updateClaimPaymentAddress(address: RepairingAddress | ApiError) {
        if (address instanceof ApiError) return;
        const claim = this.currentClaim.value;
        if (claim)
            claim.repairing_dealer_address = address;
        this.currentClaim.next(claim);
    }

    public checkIfDetailsOpened(){
        let temp = false
        this.isDetailsOpen.subscribe( theData=>{
            temp = theData
        })
        return temp
    }

}