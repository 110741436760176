<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <mat-card class="example-card">
                <mat-card-title>Reset Password</mat-card-title>
                <mat-card-content>
                    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" >
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Email</mat-label>
                            <input type="text" matInput placeholder="Email" formControlName="email" value="test@test.com">
                          </mat-form-field>
                          <button mat-flat-button color="primary mb-3">Reset Password</button>
                    </form>
                    {{outputMessage}}
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>