import { DatePipe, getLocaleId } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

const getCdtCstOffset = () => {
    const getNthSunday = (date, nth) => {
        date.setDate((7*(nth-1))+(8-date.getDay()));
        return date;
    }
    const isCdtTimezoneOffset = (today) => {
      //console.log('Today : ', today);
      let dt = new Date();
      var mar = new Date(dt.getFullYear(), 2, 1);
      mar = getNthSunday(mar, 2);
     // console.log('CDT Start : ', mar);
      var nov = new Date(dt.getFullYear(), 10, 1, 23, 59, 59);
      nov = getNthSunday(nov, 1);
      //console.log('CDT End : ', nov);
      return mar.getTime()< today.getTime() && nov.getTime()> today.getTime();
    }
    var today = new Date()// current date
    if (isCdtTimezoneOffset(today)) {
      return -300
    } else {
      return -360
    }
  }

@Pipe({
    name: 'epgDate',
})
export class EpgDatePipe implements PipeTransform {

    constructor(private datePipe: DatePipe, @Inject(LOCALE_ID) private locale: string) { }

    transform(value: Date | string | undefined,format: string = 'mediumDate'): string | null {
        if(value){
            const currentToCT = ((new Date()).getTimezoneOffset()) + getCdtCstOffset()
            // console.log(((new Date()).getTimezoneOffset()) , getCdtCstOffset())
            // console.log(currentToCT)
            const dateValue = new Date(value);
            var userTimezoneOffset = currentToCT * 60000;
            var date = new Date(dateValue.getTime() + userTimezoneOffset);
          // console.log(date)
            return this.datePipe.transform(date, format);
        }
       return null;
    }

}
