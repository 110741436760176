import { Platform } from "@angular/cdk/platform";
import { formatDate } from "@angular/common";
import { Inject, Optional } from "@angular/core";
import { Injectable } from "@angular/core";
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from "@angular/material/core";
import { Subject, Observable } from "rxjs";

let SUPPORTS_INTL_API: boolean;

// We need a try/catch around the reference to `Intl`, because accessing it in some cases can
// cause IE to throw. These cases are tied to particular versions of Windows and can happen if
// the consumer is providing a polyfilled `Map`. See:
// https://github.com/Microsoft/ChakraCore/issues/3189
// https://github.com/angular/components/issues/15687
try {
  SUPPORTS_INTL_API = typeof Intl != 'undefined';
} catch {
  SUPPORTS_INTL_API = false;
}

@Injectable()
export class EpgDateAdapter extends NativeDateAdapter {
    private readonly _ieDate: boolean;

    constructor(@Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string, platform: Platform){
        super(matDateLocale,platform);
        this._ieDate = platform.TRIDENT || platform.EDGE;
    }
    format(date: Date, displayFormat: Object): string{
        const offset = date.getTimezoneOffset();
        const newDate = '';
        if (SUPPORTS_INTL_API) {
            // On IE and Edge the i18n API will throw a hard error that can crash the entire app
            // if we attempt to format a date whose year is less than 1 or greater than 9999.
            if (this._ieDate && (date.getFullYear() < 1 || date.getFullYear() > 9999)) {
              date = this.clone(date);
              date.setFullYear(Math.max(1, Math.min(9999, date.getFullYear())));
            }
      
            displayFormat = {...displayFormat, timeZone: 'utc'};
      
            const dtf = new Intl.DateTimeFormat(this.locale, displayFormat);
            //return this._stripDirectionalityCharacters(this._format(dtf, date));
            return this.formatDate(dtf, date)
          }
          return date.toString()
    }

    formatDate(dtf: Intl.DateTimeFormat, date: Date) {
        // Passing the year to the constructor causes year numbers <100 to be converted to 19xx.
        // To work around this we use `setUTCFullYear` and `setUTCHours` instead.
        const d = new Date();
        d.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        d.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
        return dtf.format(d);
      }
       
}