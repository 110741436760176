import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ChatMessageService } from '../../services/chat-mesage/chat-message.service';

@Component({
  selector: 'chat-messenger',
  templateUrl: './chat-messenger.component.html',
  styleUrls: ['./chat-messenger.component.scss'],
  providers: [ChatMessageService]
})
export class ChatMessengerComponent implements OnInit, OnDestroy {

  public message: string | null = null;
  public httpMessage: string | null = null;
  public isSubmitting: boolean = false;

  constructor(private chatService: ChatMessageService) { }
  ngOnDestroy(): void {
    this.isSubmitting = false;
    this.message = null;
    this.httpMessage = null;
  }

  ngOnInit(): void {
  }

  show = false;

  clickit($event): void {
    $event.stopPropagation();
    this.show = !this.show;
    this.message = null;
    this.httpMessage = null;
    this.isSubmitting = false;
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.show = false;
  }

  sendMessage() {
    if (this.message) {
      this.isSubmitting = true;
      this.chatService.sendMessage(this.message)
        .subscribe(
          response => {
            this.httpMessage = 'Mesage has been recieved';
            setTimeout(() => {
              this.show = false;
            }, 1000);
          },
          error => {
            this.httpMessage = 'An error has occured';
          },
          () => {
            setTimeout(() => {
              this.message = null;
              this.httpMessage = null;
              this.isSubmitting = false;
            }, 1000);
          }
        );
    }
  }

}
