import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  public autocomplete(term: string): Observable<{ [key: string]: string }[]>{
      let params = new HttpParams();
      params = params.append('_search',''+1)
      params = params.append('term',term)
      return this.http.get<{ [key: string]: string }[]>('api/v1/espp/customers',{params})
  }
}
