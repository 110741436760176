import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

export type SortOrder = 'asc' | 'desc';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

    transform(value: any[], sortOrder: SortOrder | string = 'asc', sortKey?: string): any {

        sortOrder = sortOrder && (sortOrder.toLowerCase() as any);
    
        if (!value || (sortOrder !== 'asc' && sortOrder !== 'desc') || !sortKey ) return value;

        let resultArray:any = []
        resultArray = _.sortBy(value, [sortKey]);

        return sortOrder === 'asc' ? resultArray : resultArray.reverse(); 
        

      }

}
