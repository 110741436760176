<!-- <loading-screen></loading-screen> -->

<!-- <div [@routeAnimations]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</div> -->

<!-- <SnackbarProvider> -->
    <app-cookie-consent></app-cookie-consent>
    <router-outlet></router-outlet>
<!-- </SnackbarProvider> -->

<!-- <router-outlet></router-outlet> -->

<!-- <div class="toast-container">
    <div class="toast" [class.show]="!(onlineSubscription | async)" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <img class="rounded me-2" alt="...">
          <strong class="me-auto">EPG Insurance</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Please connect to the internet.
        </div>
      </div>
</div> -->