/** Angular modules */
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
/** rxjs */
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';

const CONTENT_TYPE_HEADER = 'application/json';
const ACCEPT_HEADER = ' application/json';
interface PrmTypes { [$key: string]: string | number | boolean}
@Injectable()
export class AppDataService {
  constructor(private http: HttpClient, private injectorService: Injector) {}
  /**
   * Gets constructed Http GET
   *
   * @param {string} qry
   * @param {PrmTypes} prm
   * @returns {Observable<any>}
   * @memberof AppDataService
   */
  public get<T>(qry: string, prm: PrmTypes): Observable<T> {
    const heads = {
      'cache-control': 'no-cache,no-store',
      expires: '0',
      Pragma: 'no-cache',
    };

    const headers = { ...heads, ...this.getHeader };
    const options = { headers: headers };
    const formattedString = this.formatQuery(qry, prm);
    return this.http
      .get<T>(formattedString, options)
      .pipe(catchError((error) => throwError(error)));
  }
  /**
   * Gets constructed Http DELETE
   *
   * @param {string} qry
   * @param {PrmTypes} prm
   * @returns {Observable<any>}
   * @memberof AppDataService
   */
  public delete<T>(qry: string, prm: PrmTypes): Observable<T> {
    const heads = {
      'Content-type': CONTENT_TYPE_HEADER,
      accept: ACCEPT_HEADER,
    };

    const headers = { ...heads, ...this.getHeader };

    const options = { headers: headers };
    const formattedString = this.formatQuery(qry, prm);
    return this.http
      .delete<T>(formattedString, options)
      .pipe(catchError((error) => throwError(error)));
  }
  /**
   * Gets constructed Http POST
   *
   * @param {string} qry
   * @param {PrmTypes} prm
   * @param {*} data
   * @returns {Observable<any>}
   * @memberof AppDataService
   */
  public post<T, S>(qry: string, prm: PrmTypes, data: S): Observable<T> {
    const heads = {
      'Content-type': CONTENT_TYPE_HEADER,
    };

    const headers = { ...heads, ...this.getHeader };
    const options = { headers: headers };
    const aQry = this.formatQuery(qry, prm);
    return this.http
      .post<T>(aQry, data, options)
      .pipe(catchError((error) => throwError(error)));
  }
  /**
   * Gets constructed Http PUT
   *
   * @param {string} qry
   * @param {PrmTypes} prm
   * @param {*} data
   * @returns {Observable<any>}
   * @memberof AppDataService
   */
  public put<T, S>(qry: string, prm: PrmTypes, data: S): Observable<T> {
    const heads = {
      'Content-type': CONTENT_TYPE_HEADER,
      accept: ACCEPT_HEADER,
    };

    const headers = { ...heads, ...this.getHeader };
    const options = { headers: headers };
    const aQry = this.formatQuery(qry, prm);
    return this.http
      .put<T>(aQry, data, options)
      .pipe(catchError((error) => throwError(error)));
  }
  /**
   * Gets constructed Headers
   * @private
   * @returns {headers}
   * @memberof AppDataService
   */
  private get getHeader(): any {
    const authService = this.injectorService.get(AuthenticationService);
    const token = authService.token
      ? `Bearer ${authService.token}`
      : 'unauthorized';

    return {
      Authorization: token,
    };
  }
  /**
   * @private
   * @param {string} query
   * @param {PrmTypes} parameters
   * @returns {string}
   * @memberof AppDataService
   * @description Formats query parameters { id: id_value, name: name_value } => ?id=id_value&name=name_value
   */
  public formatQuery(query: string, parameters: PrmTypes): string {
    let stringBuilder = query;
    const parameterLength = 2;

    if (arguments.length !== parameterLength) {
      return stringBuilder;
    }

    const queryParams = parameters;
    const arrayKeys = Object.keys(queryParams)
    const firstKey = 0;
    for (const [index, element] of arrayKeys.entries()) {
      if (element !== undefined && element !== null) {
        if (index === firstKey) {
          stringBuilder = stringBuilder.concat('?');
        }
        stringBuilder = stringBuilder.concat(
          `${element}=${queryParams[element]}`
        );
        if (index < arrayKeys.length - 1) {
          stringBuilder = stringBuilder.concat('&');
        }
      }
    }
    return stringBuilder;
  }
}
