import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IAppConfig } from './IAppCOnfig';
//`0oa1f9ffd38nw98Y80h8`
enum OktaScopes {
    openid = 'openid',
    email = 'email',
    profile = 'profile',
    groups = 'groups'
}
export type OktaConfig = {
    clientId: string,
    issuer: string,
    scopes: string[],
    // oidc: {
    //   clientId: string,
    //   issuer: string,
    // //   redirectUri: string,
    // //   scopes: OktaScopes[],
    // //   testing: {
    // //     disableHttpsCheck: boolean
    // //   },
    // },
    // widget: {
    //   useInteractionCodeFlow: boolean,
    // },
    // resourceServer: {
    //   messagesUrl: string,
    // },  
}

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {

    private config: IAppConfig | undefined;

    constructor(private http: HttpClient) { }

    loadAppConfig(): Observable<IAppConfig | undefined> {
        return this.http.get<IAppConfig>('assets/config/config.json')
            .pipe(
                catchError(error => of(undefined)),
                tap(data => {
                    this.config = data;
                })
            )
    }

    getLocalConfig() {
        const settings = require('../assets/config/config.json');
        this.config = settings;
    }

    get esppBaseApi() {
        return this.config ? this.config.esppApiServer.baseUrl : '';
    }

    get oktaConfig(): OktaConfig {
        if (this.config == undefined) {
            throw new Error('Unable to load config');
        }
        return {
            clientId: this.config.oktaConfig.clientId,
            issuer: this.config.oktaConfig.issuer,
            scopes: this.config.oktaConfig.scopes
        };
    }
}
