import { Directive, OnInit } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
    selector: '[appSelectOption]'
})
export class SelectOptionDirective implements OnInit {

    constructor(private select: MatSelect) { }
    ngOnInit(): void {
       
        this.select.openedChange.subscribe(isOpen => {
            if(isOpen) {
              console.log('open', this.select.panel);
            }
          });

          this.select.optionSelectionChanges.subscribe(o => console.log(o.source._getHostElement))
    }



}
