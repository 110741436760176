import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLocaleData } from '@angular/common';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

//import localeCa from '@angular/common/locales/en';
// registerLocaleData(localeCa);     // es-ES


// require('bootstrap')
// require('dotenv').config();

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
