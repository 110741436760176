import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    
    constructor(private authService: AuthenticationService) { }

    ngOnInit(): void {
    }

    mailto(email: string) {
       return `mailto:${email}?subject="Contact Us"`;
    }

    public get user() {
        return this.authService.user;
    }

    public get currentMfrProgram(): string {
        if(this.user == null) return 'EPG';
        const mfrProgram = this.authService.currentMfrProgram;
        return mfrProgram?.name ?? 'EPG';
    }

    public get userCountry(){
        return this.authService.user;
    }
}