import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
    message: string
  }

@Component({
  selector: 'error-reporter',
  templateUrl: './error-reporter.component.html',
  styleUrls: ['./error-reporter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorReporterComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
  }

}
