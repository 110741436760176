import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type MockedData = number | string;
type MockedDataMap = Map<string, MockedData>;

@Injectable({
    providedIn: 'root'
})
export class MockedDataService {

    public mockedDataMap = new BehaviorSubject<MockedDataMap>(new Map<string, MockedData>());

    constructor() { }

    //method that adds key to map and sets value
    public addMockedData(key: string, value: MockedData): void {
        const mockedDataMap = this.mockedDataMap.getValue();
        mockedDataMap.set(key, value);
        this.mockedDataMap.next(mockedDataMap);
    }

    //reset mockeddatamap
    public resetMockedData(): void {
        this.mockedDataMap.next(new Map<string, MockedData>());
    }
}
