import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppSetting, AppSettingStore } from 'src/app/core/storage/stores/app-settings';
import { Application } from './application.enum';
type Header = {
    header_value: string,
    header_key: string,
    app_key: string,
}
type AppInformation = {
    headers: Header[],
    claim_app: {allowed_file_types: Array<string>},
    quoting_app: { available_funds: AvailableFunds, allowed_brands: Array<string>},
    permissions: Array<{name: string}>
}

type AvailableFund = {
    order: number;
    type: string;
    code: string;
};

type AvailableFunds = Array<AvailableFund>;
export type FeatureFlags = Array<{
    name: string;
}>;

export enum FeatureFlag {
    presaleUnit = 'presale_unit',
    claimsDecom = 'claims_decom'
}

@Injectable({
    providedIn: 'root'
})
export class AppInformationService {

    public headers: BehaviorSubject<Header[]> = new BehaviorSubject<Header[]>([]);
    public quoteAvailableFunds: BehaviorSubject<AvailableFunds> = new BehaviorSubject<AvailableFunds>([]);
    public quoteAllowedBrands: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    public featureFlags: BehaviorSubject<FeatureFlags> = new BehaviorSubject<FeatureFlags>([]);

    constructor(private http: HttpClient) { }

    public getAppInformation(application: Application): Observable<AppInformation> {
        //new Date().getTime() > this.ttl
        if(true){
            let params = new HttpParams();
            params = params.append('application', application);
            const requestOptions = {params};
            return this.http.get<AppInformation>('api/v1/app/meta',requestOptions)
                .pipe(
                    take(1),
                    tap( info => {
                        this.storeTTL();
                        this.headers.next(info.headers);
                        this.storeHeaders(info.headers);
                        this.storeQuoteConfigurations(info.quoting_app);
                        this.storeFeatureFlags(info.permissions)
                        this.storeAllowedBrands(info.quoting_app.allowed_brands);
                    })
                );
        }
        return of({headers:[],claim_app: {allowed_file_types: []},quoting_app: {available_funds: [], allowed_brands: []}, permissions: []})
    }

    private storeAllowedBrands(brands: string[]){
        this.quoteAllowedBrands.next(brands);
    }

    public storeFeatureFlags(flags: FeatureFlags): void
    {
        this.featureFlags.next(flags);
    }

    private storeHeaders(headers: Header[]){
        const headerStore = {};
        headers.forEach( header => {
            headerStore[header.header_key] = header.header_value
        })
        const headersObject = headers.reduce( (acc, header) => {
            let {header_key, header_value} = header
            return {...acc, [header_key]: header_value};
        },{})

        // const test = headers.reduce( (acc, header) => {
        //     let {header_key, header_value, app_key} = header
        //     return {...acc, [app_key]: {...acc[app_key],[header_key]: header_value}  };
        // },{})

        AppSettingStore.getInstance().store(AppSetting.Headers, JSON.stringify(headersObject))
    }

    public getHeaders(): Header[]{
        let headers = AppSettingStore.getInstance().headers
        return headers? JSON.parse(headers) : [];
    }

    private getCacheTTL(){
        return new Date().setTime(new Date().getTime() + (1*60*60*1000));
    }

    public get ttl(): number{
        const ttl = AppSettingStore.getInstance().get(AppSetting.TTL)
        return ttl? +ttl : 0;
    }

    private storeTTL(){
        const ttl = this.getCacheTTL();
        AppSettingStore.getInstance().store(AppSetting.TTL,ttl)
        //localStorage.setItem('APP_META_TTL',ttl+'')
    }

    private storeQuoteConfigurations(configuration: {
        available_funds: AvailableFunds
    }): void
    {
        this.quoteAvailableFunds.next(configuration.available_funds);
    }
}
