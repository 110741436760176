import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { ApiError, ApiErrorFactory, ApiErrorParserInterface } from 'src/app/core/api-errors/ApiError';
import { RepairingAddress } from './claim.service';

export interface PaymentAddressInterface {
    createRepairingAddress(claimID: number, paymentData): Observable<RepairingAddress | ApiError> ;
    updateRepairingAddress(claimID: number, paymentAddressID: number, paymentData): Observable<RepairingAddress | ApiError> ;
}


@Injectable({
    providedIn: 'root'
})
export class RepairingDealerAddresssService implements PaymentAddressInterface, ApiErrorParserInterface {
    public repairingDealerErrors = new Subject<ApiError | null>();
 
    constructor(private http: HttpClient) { }

    parseError(errors: ApiError): void {
        
    }

    public createRepairingAddress(claimID: number, paymentData): Observable<RepairingAddress | ApiError> {
        paymentData['claim_id'] = claimID
        this.repairingDealerErrors.next(null);
    
        return  this.http.post<RepairingAddress>('api/v1/espp/claims/' + claimID + '/repairing_dealer_address', paymentData)
            .pipe(
                take(1),
                catchError(errorResponse => {
                    const apiError = new ApiErrorFactory(errorResponse).make();
                    this.repairingDealerErrors.next(apiError)
                    return of(apiError);
                })
            );
    }

    public updateRepairingAddress(claimID: number, paymentAddressID: number, paymentData): Observable<RepairingAddress | ApiError> {
        paymentData['claim_id'] = claimID;
        paymentData['id'] = paymentAddressID;
        
        this.repairingDealerErrors.next(null);
        return  this.http.patch<RepairingAddress>('api/v1/espp/claims/' + claimID + '/repairing_dealer_address' + '/' + paymentAddressID, paymentData)
            .pipe(
                take(1),
                catchError(errorResponse => {
                    const apiError = new ApiErrorFactory(errorResponse).make();
                    this.repairingDealerErrors.next(apiError)
                    console.log(apiError)
                    return of(apiError);
                })
            );
    }
}
