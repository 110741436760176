import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';
import { QuotePermission } from 'src/app/core/permissions/QuotePermission';

import { User } from 'src/app/shared/models/User';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    
    loginForm: FormGroup = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email]),
        password: new FormControl(null, [Validators.required, Validators.minLength(8)])
    });

    loading = false;
    spin(): void {
        this.loading = true;
    }

    public hide = true;
    public resetPassword: boolean = false;
    outputMessage: string | null = null;
    status = 0;

    errorMessage: string | null = null;

    constructor(
        private router: Router,
        private authService: AuthenticationService) { }

    ngOnInit(): void { }


    get email(): AbstractControl {
        return this.loginForm.controls.email;
    }

    get password(): AbstractControl {
        return this.loginForm.controls.password;
    }

    onSubmit(): void {
        if (!this.loginForm.valid) { return; }
        this.loginForm.disable({emitEvent: false});
        this.authService.login({
            email: (this.email.value).toLowerCase(),
            password: this.password.value
        })
            .subscribe(
                (user: User) => {
                    const readQuote = user.permissions.findIndex(p => p.name == QuotePermission.READ_QUOTE)
                    if (readQuote >= 0) {
                        this.router.navigate(['/apps', 'espp', 'quotes']);
                        return;
                    }
                    this.router.navigate(['/apps', 'espp', 'claims']);
                },
                error => {
                    this.errorMessage = error;
                    this.loading = false;
                    this.password.reset();
                    this.loginForm.enable({emitEvent: false});
                }
            );
    }

    /**
     * OnLoginClick
     */
    OnregisterClick(): void {
        this.router.navigate(['/register']);
    }

    resetPassowrd(): void {
        this.resetPassword = !this.resetPassword;
    }


}
