import { Component, OnInit, Inject, Optional, NgZone, HostListener, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  Router } from '@angular/router';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, {  Tokens } from '@okta/okta-auth-js';
import { of, Subscription, timer } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app-config.service';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';
import { oktaBaseUrl, oktaClientID } from 'src/app/okta.config';
//import sampleConfig from '../../../okta.config';

const DEFAULT_ORIGINAL_URI = window.location.origin;

const getAuthStatus = tokens => {
    if (tokens.accessToken) {
        const expiresAt = tokens.accessToken.expiresAt * 1000;
        const seconds = (expiresAt - Date.now()) / 1000;
        return { loggedIn: true, remainingSeconds: seconds };
    }
    return { loggedIn: false, remainingSeconds: 0 };
}

//US Dealers: 800-296-1043
//Canadian Dealers: 800-296-1064
export class DynamicContext {
    private host: string;
    constructor(host: string) {
        this.host = host;
    }
    private supportPhoneNumbers = {
        'ppp.cnhinsurance.com': {
            'US': '8002961043',
            'CA': '8002961064'
        },
        '*': {
            'US': '8664082881'
        }
    };

    public handle(): void {

    }
}

@Component({
    selector: 'app-login-form',
    //templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
    template: `
        <div *ngIf="dialogRef && !!isAuthenticated" class="session-login-header"><button mat-button [mat-dialog-close]="true"><mat-icon>close</mat-icon></button></div>
        <div id="sign-in-widget">
            <div class="brand-login"></div>
        </div>
        <div id="reg-country-selection" *ngIf="showRegistration && !isLoggedInWithOkta">
            <div class="row" *ngIf="!isCNH">
                <div class="d-flex justify-content-start align-items-center">
                        <p>For password assistance, please contact us at (866) 408-2881.</p>
                </div>
            </div>
            <div class="row" *ngIf="isCNH">
                <div class="d-flex justify-content-start align-items-center">
                        <p>For US password assistance, please contact us at (800) 296-1043.</p>
                </div>
            </div>
            <div class="row" *ngIf="isCNH">
                <div class="d-flex justify-content-start align-items-center">
                        <p>For CA password assistance, please contact us at (800) 296-1064.</p>
                </div>
            </div>
            <div class="row">
                <div class="d-flex justify-content-start align-items-center pt-1">
                    <p>Don't have an account? Select your country to register now.</p>
                </div>
            </div>
            <div class="row">
                <div class="d-flex justify-content-center align-items-center">
                    <img (click)="onregisterClick()" src="./assets/images/us.svg" class="me-4" width="24" title="United States">
                    <img (click)="onregisterClick('CA')" src="./assets/images/ca.svg" class="me-4" width="24" title="Canada">
                    <img (click)="onregisterClick('AU')" src="./assets/images/au.svg" class="me-4" width="24" title="Australia">
                    <img (click)="onregisterClick('NZ')" src="./assets/images/nz.svg" class="me-4" width="24" title="New Zealand">
                </div>
            </div>
        </div>
         <div *ngIf="dialogRef && isAuthenticated" mat-dialog-content>
            Your session will end in {{minutesLeft}} minutes. Please renew session.
         </div>
        <div id="espp-login" *ngIf="isLoggedInWithOkta">
        <!-- <div class="o-form-error-container o-form-has-errors" data-se="o-form-error-container"><div><div class="okta-form-infobox-error infobox infobox-error" role="alert"><span class="icon error-16"></span><p>Authentication failed</p></div></div></div> -->
            <div class="text-center mb-3" *ngFor="let error of errorBag">{{error}}</div>
            <div class="text-center mb-3" *ngIf="appLoadingMessage">{{appLoadingMessage}}</div>
            <!-- <div>Signing In</div> -->
            <mat-spinner *ngIf="showSpinner" class="mx-auto pt-3"></mat-spinner>
        </div>
    `,
    //changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormComponent implements OnInit {

    public errorBag: Array<string> = [];
    private loginErrorSubscription = new Subscription();
    public isAuthenticated = false;
    errorMessage: string | null = null;
    signIn: any;
    public hostInformation: { country: string, host: string } | null = null;
    appLoadingMessage: string | null = null;
    showSpinner: boolean = true

    constructor(
        private settingsService: AppConfigService,
        private dialog: MatDialog,
        private router: Router,
        private okta: OktaAuthStateService,
        private authService: AuthenticationService,
        @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
        @Optional()
        @Inject(MAT_DIALOG_DATA) public data: { showRegister: false, showSessionTImer: false }
    ) {
        const loginUrl = new URLSearchParams(window.location.search);
        const activationToken = loginUrl.get('token');
        const tempPassword = loginUrl.get('temp_password');
    }


    public isLoggedInWithOkta = false;
    ngOnInit(): void {
        this.checkCookie();
        // When navigating to a protected route, the route path will be saved as the `originalUri`
        // If no `originalUri` has been saved, then redirect back to the app root
        const originalUri = this.oktaAuth.getOriginalUri();
        if (!originalUri || originalUri === DEFAULT_ORIGINAL_URI) {
            this.oktaAuth.setOriginalUri('/');
        }


        timer(0, 5000)
            .pipe(
                switchMap(t => this.okta.authState$),
                filter(state => state.isAuthenticated != undefined),
                tap(state => {
                    const expiresAt = (state.accessToken?.expiresAt ?? 0) * 1000;
                    const seconds = (expiresAt - Date.now()) / 1000;
                    this.minutesLeft = Math.floor(seconds / 60);
                }),
                map(state => <boolean>state.isAuthenticated)
            )
            .subscribe(state => this.isAuthenticated = state)

        of(window.location.hostname)
            .pipe(
                switchMap(host => {
                    switch (host) {
                        case 'ppp.cnhinsurance.com':
                        case 'dev-espp.epgins.com':
                            return of({ country: 'CA', ...{ host } });
                        default:
                            return of({ country: 'US', ...{ host } });
                    }
                })
            )
            .subscribe(country => {
                this.hostInformation = country;
            })

    }
    public minutesLeft = 0;

    ngOnDestroy() {
        this.signIn.remove();
        this.loginErrorSubscription.unsubscribe();

    }

    public get isCNH(): boolean {
        if (this.hostInformation) {
            return [this.hostInformation.host].some(host => ['ppp.cnhinsurance.com', 'espp-integration-model.epgins.com'].includes(host));
        }
        return false;
    }

    /**
     * OnLoginClick
     */
    onregisterClick(country: 'US' | 'CA' | 'AU' | 'NZ' = 'US'): void {
        this.router.navigate(['/register', country]);
    }


    public isModal(): boolean {
        return window.location.pathname.includes('login');
    }

    public get dialogRef(): MatDialogRef<LoginFormComponent> | undefined {
        return this.dialog.openDialogs.find(ref => ref.componentInstance instanceof LoginFormComponent);
    }

    public get showRegistration(): boolean {
        return this.data ? this.data.showRegister : true;
    }

    checkCookie() {
        var cookieEnabled = navigator.cookieEnabled;
        console.log(cookieEnabled)
        if (!cookieEnabled) {
            document.cookie = "testcookie";
            cookieEnabled = document.cookie.indexOf("testcookie") != -1;
        }
        return cookieEnabled || this.showCookieFail();
    }

     showCookieFail(){
        this.errorBag.push(
            `Please enable third party cookies for login functionality.`
        )
      }
}