import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogModel } from './epg-dialog.component';
import { EpgDialogModule } from './epg-dialog.module';

@Injectable({
  providedIn: 'root'
})
export class DialogServiceService {

  constructor(private dialog: MatDialog) { }

  open(dialogData: ConfirmationDialogModel){
    this.dialog.open(EpgDialogModule, {
        data: dialogData
    })
  }
}
