import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { CONTENT } from './cookie-consent.constants';

const domain = window.location.hostname;
/**
 * This is the config used for the Cookie consent promt
 * @export
 * @constant cookieConfig
 */
export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain, // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: 'bottom',
  theme: 'classic',
  type: 'info',
  content: CONTENT
};
