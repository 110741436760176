import { Component, Host, Inject, Input, OnInit, ViewContainerRef } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { element } from 'protractor';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DebugService } from './debug.service';

 interface AllValidationErrors {
  control_name: string;
  error_name: string;
  error_value: any;
}

 interface FormGroupControls {
  [key: string]: AbstractControl;
}

//  function getFormValidationErrors(controls: FormGroupControls): AllValidationErrors[] {
//   let errors: AllValidationErrors[] = [];
//   Object.keys(controls).forEach(key => {
//     const control = controls[ key ];
//     if (control instanceof FormGroup) {
//       errors = errors.concat(getFormValidationErrors(control.controls));
//     }
//     const controlErrors: ValidationErrors | null = controls[ key ].errors;
//     console.log(controlErrors)
//     if (controlErrors !== null) {
//       Object.keys(controlErrors).forEach(keyError => {
//         errors.push({
//           control_name: key,
//           error_name: keyError,
//           error_value: controlErrors[ keyError ]
//         });
//       });
//     }
//   });
//   return errors;
// }

export function getFormValidationErrors(form: FormGroup) {

    const result: Array<{control: string, error: string,value: string}> = [];
    Object.keys(form.controls).forEach(key => {
      const formProperty = form.get(key);
      if (formProperty instanceof FormGroup) {
        result.push(...getFormValidationErrors(formProperty))
      }
      const controlErrors: ValidationErrors | undefined | null = formProperty?.errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
            if(!key) return;
            result.push({
                control: key,
                error: keyError,
                value: controlErrors[keyError]
            })
        });
      }
    });
  
    return result;
  }

@Component({
    selector: 'app-debug-panel',
    templateUrl: './debug-panel.component.html',
    styleUrls: ['./debug-panel.component.scss']
})
export class DebugPanelComponent implements OnInit {

    //@Input() parentComponent: Array<any> = [];

    public compoents: Array<Component> = [];

    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: {components: Array<any>}, private debugService: DebugService) { }

    ngOnInit(): void {
    }

    public get debuggableKeys(): Array<string> {
        return Array.from(this.debugabbles.keys());
    }

    private isDebuggable(component: Component): boolean {
        return 'debugableAttributes' in component
    }

    public get debugableComponents() {
        return this.data.components.filter(component => this.isDebuggable(component));
    }

    public get debugabbles(): Map<string, any> {
        const array: Map<string, any> = new Map<string, any>();
        this.debugableComponents.forEach(
            component => {
                const debuggableKeys = component.debugableAttributes();
                Object.keys(component).forEach(key => {
                    if (debuggableKeys.includes(key)) {
                        array.set(key, this.serializeObject(key, component[key]))
                    }
                })
            }
        );
        return array
    }

    private serializeObject(key: string, object: any): any {
        switch (true) {
            case object instanceof FormGroup:
                return {...object.value,...{formErrors: getFormValidationErrors(object)}};
            case object instanceof BehaviorSubject:
                return object.value;
            case object instanceof ActivatedRoute:
                return {
                    params: object.params.value,
                    fragment: object.fragment.value,
                    queryParams: object.queryParams.value
                }
            default:
                return object;
        }
    }

    /**
    * Flatten a multidimensional object
    *
    * For example:
    *   flattenObject{ a: 1, b: { c: 2 } }
    * Returns:
    *   { a: 1, c: 2}
    */
    public flatten(data: any, response = {}, flatKey = "", onlyLastKey = false) {
        for (const [key, value] of Object.entries(data)) {
          let newFlatKey: string;
          if (!isNaN(parseInt(key)) && flatKey.includes("[]")) {
            newFlatKey = (flatKey.charAt(flatKey.length - 1) == "." ? flatKey.slice(0, -1) : flatKey) + `[${key}]`;
          } else if (!flatKey.includes(".") && flatKey.length > 0) {
            newFlatKey = `${flatKey}.${key}`;
          } else {
            newFlatKey = `${flatKey}${key}`;
          }
          if (typeof value === "object" && value !== null && Object.keys(value).length > 0) {
            this.flatten(value, response, `${newFlatKey}.`, onlyLastKey);
          } else {
            // if(onlyLastKey){
            //     newFlatKey = newFlatKey.split(".").pop();
            // }
            if (Array.isArray(response)) {
              response.push({
                [newFlatKey.replace("[]", "")]: value
              });
            } else {
              response[newFlatKey.replace("[]", "")] = value;
            }
          }
        }
        return response;
      }

      public getflattendKeys(flattenedObject){
        return Object.keys(flattenedObject);
      }

      download(){
        this.debugService.downloadFile(this.debugabbles);
      }
}
