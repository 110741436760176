import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
export interface SerialNumber {
    coverages: {
        effective_date: Date
        expiration_date: Date
        coverage: string
        term: number
        hours: number
    }[]
    deliver_date: Date
    epg_model: string
    equipment_type: string
    manufacturer: string
    model_version: string
    model_year: number
    pin_17: string
    serial_number: string
    shipment_date: Date
    warranties: any[]
    warranty_expiration: Date
    warranty_hours: number
    warranty_term: number,
}

@Injectable({
    providedIn: 'root'
})
export class Pin17Service {

    protected equipmentSelected = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient) { }

    public searchPin17(pin: string | null): Observable<SerialNumber[]> {
        if (pin && pin.length > 3) {
            const opts = { params: { term: pin } };
            return this.http.get<SerialNumber[]>('api/v1/espp/pin17/search', opts)
                .pipe(
                    catchError(error => of([]))
                )
        }
        return of([])
    }
}
