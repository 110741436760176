import { Injectable, Injector, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { map, tap, take, catchError, find, isEmpty, filter, first } from 'rxjs/operators';
import { NavigationStart, PRIMARY_OUTLET, Router, UrlTree } from '@angular/router';
import { OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { MatDialog } from '@angular/material/dialog';
import { error } from 'console';

import { AuthorizationService } from './authorization.service';
import { MfrProgram, Registration, JWT, UserAuth } from './authentication.types';
import { User } from 'src/app/shared/models/User';
import { ClaimPermission } from '../permissions/ClaimPermission';
import { QuotePermission } from '../permissions/QuotePermission';
import { LoginFormComponent } from 'src/app/shared/components/login-form/login-form.component';
import { USER_KEY } from './constants';
import { FORGOT_PASSWORD, LOGIN, LOGOUT, REGISTER_EMAIL, REQUEST_ACCESS, SSO_LOGIN, USER, USER_INFO, USER_LOGIN } from 'src/app/shared/api.constants';
import { Permission } from './permissions.types';
/**
 * This service is used for Handling Authentication state
 * @exports
 * @class AuthenticationService
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    user: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
    mfrProgram: BehaviorSubject<string> = new BehaviorSubject<string>('EPG');
    ssoAppLoadingMessage: Subject<string | null> = new Subject<string | null>()
    public loginErrorMessages: Subject<Array<string>> = new Subject();

    constructor(private http: HttpClient,
        private router: Router,
        private okta: OktaAuthStateService,
        private dialog: MatDialog,
        protected injector: Injector) { }

    public get storedUser(): UserAuth | null {
        return sessionStorage.getItem(USER_KEY) 
            ? JSON.parse(sessionStorage.getItem(USER_KEY)||'') 
            : null;
    }

    public storeUser(userData: UserAuth | null) {
        sessionStorage.setItem(USER_KEY, JSON.stringify(userData));
    }

    login(credential: {
        email: string
        password: string
    }): Observable<User> {
        return this.http.post<User>(LOGIN, credential)
            .pipe(
                catchError(
                    errorResponse => {
                        const errorCode = errorResponse.status;
                        switch (errorCode) {
                            case 422:
                                return throwError('The provided credentials are incorrect.');
                            default:
                                return throwError('Can Not Proceed');
                        }
                    }
                ),
                tap(user => this.handleAuthentication(user))
            );
    }

    // public oktaLogin(user: User) {
    //     this.handleAuthentication(user)
    // }

    public oktaLogin(bearerToken: JWT): void {
        /**
         * Obtain Authorization service instance without circular dependency
         */
        const authorizationService = this.injector.get(AuthorizationService);
         this.http.post<User>(USER_LOGIN, {}, { headers: { 'AUthorization': 'Bearer ' + bearerToken } })
            .pipe(
                take(1)
            )
            .subscribe(
                (user: User) => {
                    authorizationService.initializePermissions(user).then(() => {})
                    const loginForm = this.dialog.openDialogs.find(ref => {
                        return ref.componentInstance instanceof LoginFormComponent;
                    });
                    this.handleAuthentication(user);
                    if (!loginForm) {
                        this.router.navigateByUrl(<UrlTree>this.navigateToLandingPage())
                    }
                    if (loginForm) loginForm.close();

                    const urlDestination = (<UrlTree>this.navigateToLandingPage());
                    const isQuotePage = urlDestination.root.children[PRIMARY_OUTLET].segments.findIndex(segment => segment.path === 'quotes');
                },
                (errorResponse: HttpErrorResponse) => {
                    this.loginErrorMessages.next([
                        errorResponse.error.message
                    ])
                }
            )
    }

    ssoLogin(login: {
        userID: number,
        mfrProgram: string
    }): Observable<User> {
        return this.http.post<User>(SSO_LOGIN, login)
            .pipe(
                catchError(
                    errorResponse => {
                        const errorCode = errorResponse.status;
                        switch (errorCode) {
                            case 422:
                                return throwError('The provided credentials are incorrect.');
                            default:
                                return throwError('Can Not Proceed');
                        }
                    }
                ),
                tap(user => this.handleAuthentication(user))
            );
    }

    autoLogin(): boolean {
        if (!this.storedUser) { return false; }
        const loadedUser = new User(
            this.storedUser.id,
            this.storedUser.first_name,
            this.storedUser.last_name,
            this.storedUser.email,
            this.storedUser.permissions,
            this.storedUser.mfr_programs,
            this.storedUser.view_level,
            this.storedUser.password_expiration,
            this.storedUser.locale,
            this.storedUser.staff
        );
        this.user.next(loadedUser);
        this.mfrProgram.next(this.getMfrProgram)
        return true;
    }

    logout(): Observable<User | null> {
        if (!this.user.value) return of(null);
        let userID = this.user.value!.id
        // localStorage.removeItem('user');
        // localStorage.removeItem('APP_Setting');
        return this.http.post<User>(LOGOUT, [])
            .pipe(
                catchError(
                    errorResponse => {
                        return throwError('The provided credentials are incorrect.');
                    }
                ),
                tap(
                    user => {
                        this.user.next(null);
                        //this.router.navigate(['/login']);
                        localStorage.clear();
                        sessionStorage.clear();
                        return of(null);
                    },
                    (error) => {
                        this.user.next(null);
                        this.router.navigate(['/login']);
                        sessionStorage.clear();
                        localStorage.clear();
                        return of(null);
                    })
            );
    }

    public getUserData(): Observable<User | null> {
        return this.http.get<User>(USER_INFO)
            .pipe(catchError(errorResponse => {
                return of(null);
            }));
    }

    getToken(): string | null {
        return this.storedUser?.token || null;
    }

    register(registration: Registration): Observable<User> {
        return this.http.post<User>(REQUEST_ACCESS, registration)
            .pipe(
            //tap(user => this.handleauthentication(user))
        );
    }

    forgotPassword(email: string): Observable<{ [key: string]: string }> {
        return this.http.post<{ [key: string]: string }>(FORGOT_PASSWORD, { email })
            .pipe(
                catchError(
                    errorResponse => {
                        const errorCode = errorResponse.status;
                        switch (errorCode) {
                            case 422:
                                return throwError('Unrecognized email address');
                            default:
                                return throwError('Can Not Proceed');
                        }
                    }
                )
            );
    }

    getUser(): Observable<User> {
        return this.http.get<User>(USER)
            .pipe(
                tap(user => this.user.next(user))
            );
    }

    canRegisterEmail(email: string): Observable<boolean> {
        return this.http.post<[string]>(REGISTER_EMAIL, { email })
            .pipe(
                map((emails: [string]) => {
                    return emails.length > 0 ? false : true;
                })
            );
    }

    isAuthenticated(): Observable<boolean> {
        return this.user.pipe(
            take(1),
            map(user => !!user)
        );
    }

    hasPermission(permission: string): Observable<boolean> {
        if (!this.isAuthenticated()) of(false);
        return this.user.pipe(
            take(1),
            map(user => user!.permissions),
            map(permissions => permissions.map(permission => permission.name)),
            map(userPermissions => userPermissions.indexOf(permission) !== -1)
        );
    }

    public handleAuthentication(user: User): void {
        this.user.next(user);
        this.mfrProgram.next(this.getMfrProgram);
        this.storeUser(user);
    }

    public getCurrentMfrProgram(): MfrProgram | null {
        return this.user?.value?.mfr_programs?.filter(program => program.current)[0] || null;
    }

    private get getMfrProgram(): string {
        if (!this.getCurrentMfrProgram()) return 'EPG';
        return this.getCurrentMfrProgram()!.name;
    }

    public navigateToLandingPage(): UrlTree | boolean {
        const user = this.user.value;
        if (!user) return this.router.createUrlTree(['/login']);
        /**
         * Obtain Authorization service instance without circular dependency
         */
        const authorizationService = this.injector.get(AuthorizationService);
        const hasPermission = (permission: string) => user.permissions.map(permission => permission.name).some(userPermission => userPermission === permission);
        const canQuote = authorizationService.hasPermission([Permission.VIEW_QUOTES, Permission.READ_QUOTE, Permission.CREATE_QUOTE]);
        const canClaim = authorizationService.hasPermission([ClaimPermission.READ_CLAIM, Permission.VIEW_CLAIMS, Permission.CREATE_CLAIM]);

        const canProcessed = hasPermission(Permission.READ_POLICY);
        if (canQuote) {
            this.ssoAppLoadingMessage.next('Loading quotes...');
            return this.router.createUrlTree(['/apps', 'espp', 'quotes'])
        }
        if (canClaim) {
            this.ssoAppLoadingMessage.next('Loading claims')
            return this.router.createUrlTree(['/apps', 'espp', 'claims'])
        }
        if (canProcessed) {
            this.ssoAppLoadingMessage.next('Loading processed...')
            return this.router.createUrlTree(['/apps', 'espp', 'enrolled_units']);
        }
        return this.router.createUrlTree(['/not_found']);
    }
}