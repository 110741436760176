import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRoutingModule } from './login-routing.module';
import {  ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { LoginWrapperComponent } from './login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { APP_BASE_HREF } from '@angular/common';
import {
  OktaAuthModule,
} from '@okta/okta-angular';
import { LoginFormModule } from 'src/app/shared/components/login-form/login-form.module';


@NgModule({
  declarations: [LoginWrapperComponent, ForgotPasswordComponent, LoginComponent, ResetPasswordComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    OktaAuthModule,
    SharedModule,
    LoginFormModule
  ],
  exports: [LoginComponent],
  providers: [
      { provide: APP_BASE_HREF, useValue: '' },
  ]
})
export class LoginModule { }
