import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, inject, LOCALE_ID, NgModule, ErrorHandler } from '@angular/core';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import Bugsnag from '@bugsnag/js';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocaleService } from './LocaleService';
import { ForgotPasswordModule } from './modules/forgot-password/forgot-password.module';
import { ForgotPasswordComponent } from './modules/forgot-password/components/forgot-password/forgot-password.component';
import { LoginModule } from './modules/login/login.module';
import { environment } from 'src/environments/environment';
import { AppConfigService } from './app-config.service';
import { registerLocaleData } from '@angular/common';
import { cookieConfig } from './cookie-consent.config';
import { bugsnagConfig, errorHandlerFactory } from './bugsnag.config';


// configure Bugsnag ASAP
Bugsnag.start(bugsnagConfig);

export function localeIdFactory(localeService: LocaleService) {
    return localeService.getLocale();
  }

  export function localeInitializer(localeId: string) {
    return (): Promise<any> => {
      return new Promise((resolve, reject) => {
        import(`@angular/common/locales/${localeId}.js`)
          .then(module => {
            registerLocaleData(module.default);
            resolve(true);
          }, m => {
            import(`@angular/common/locales/en.js`)
                .then(module => {
                    registerLocaleData(module.default);
                    resolve(true);
                },reject)
          });
      });
    };
  }
@NgModule({
    declarations: [
        AppComponent,
        ForgotPasswordComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        CoreModule,
        SharedModule,
        //TranslateModule.forRoot()
        ForgotPasswordModule,
        LoginModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
    ],
    providers: [
        { provide: ErrorHandler, useFactory: errorHandlerFactory },
        {
            provide: APP_INITIALIZER,
            multi: true,
            // deps: [AppConfigService],
            useFactory: (
                //appConfigSvc: AppConfigService,
            ) => {
                const settingsService = inject(AppConfigService);
                const http = inject(HttpClient);
                return () =>
                    new Promise((resolve) => {
                        // load settings for a deployed app
                        //environment.production
                        if (environment.production) {
                            settingsService.loadAppConfig()
                                .toPromise()
                                .then(config => resolve(true))
                                .catch(c => resolve(false));
                        } else {
                            // load settings for a local app
                            settingsService.getLocalConfig();
                            resolve(true);
                        }
                    });

            }
        },
        LocaleService,
    { provide: LOCALE_ID, useFactory: localeIdFactory, deps: [LocaleService] },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: localeInitializer,
      deps: [LOCALE_ID]
    },
        // {
        //     provide: LOCALE_ID,
        //     deps: [LocaleService],      //some service handling global settings
        //     useFactory: (LocaleService: { locale: string; }) => LocaleService.locale
         // },
        //{ provide: LOCALE_ID, useValue: 'fr-CA' },
        //SnackbarProvider,
        Title
    ],
    bootstrap: [AppComponent],
    //exports: [ResetPasswordComponent,ForgotPasswordComponent]
})
export class AppModule { }
//https://medium.com/dailyjs/dynamic-locales-in-angular-dd9a527ebe1f
//https://github.com/profanis/codeShotsWithProfanis/blob/44/environmental_variables_app_initializer/src/app/app.module.ts