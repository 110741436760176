import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuoteCoverageService {

  constructor(private http: HttpClient) { }

  public updateCoverage(quoteID: number,coverageID: number){
    return this.http.delete(`api/v1/quotes/${quoteID}/coverages/${coverageID}`)
}

  public delete(quoteID: number,coverageID: number){
      return this.http.delete(`api/v1/quotes/${quoteID}/coverages/${coverageID}`)
  }

  //,equipment: { serial_number: string | any, pin17: string |  any,reatial_value: number | any}
  public updateApplicationCoverageData(quoteID: number, data: {sale_date: Date | null, comments: string | null}){
    return this.http.post(`api/v1/espp/quotes/${quoteID}/application_update`,data)
  }
}
