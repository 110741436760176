import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { USER_KEY } from './core/auth/constants';

export function errorHandlerFactory() {
    return new BugsnagErrorHandler();
}
const getRelesaeStage = () => {
    const domain = window.location.hostname;

    switch (domain) {
        case 'espp.epgins.com':
        case 'ppp.cnhinsurance.com':
            return 'production';
        //model clientid
        case 'espp-integration-model.epgins.com':
        case 'espp-model.epgins.com':
            return 'model';
        //default to dev okta env
        default:
            return 'dev';
    }

}

export const bugsnagConfig = {
    apiKey: '3180455312abcbbebf78cad9c07fa830',
    releaseStage: getRelesaeStage(),
    onError: function (event) {
        const user = sessionStorage.getItem(USER_KEY)
            ? JSON.parse(sessionStorage.getItem(USER_KEY) || '')
            : null;
        user &&
            event.setUser(
                user.id,
                user.email,
                `${user.first_name} ${user.last_name}`
            );
    },
    enabledReleaseStages: ['production', 'model'],
};