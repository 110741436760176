<div class="p-2">
    <h4 mat-dialog-title>{{data.title}}</h4>

    <div mat-dialog-content>
        <p>{{data.message}}</p>
    </div>

    <div mat-dialog-actions class="d-flex justify-content-end">
        <button class="mat-flat-button me-2"(click)="close()" *ngIf="data.btnCancelText">{{data.btnCancelText}}</button>
        <button class="mat-flat-button mat-primary"(click)="confirm()">{{data.btnOkText}}</button>
    </div>
</div>