import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ToastComponent } from './toast-component/toast.component';
import {
  MessageType,
  openSnackbarOptions,
  CssClass,
} from './toast-notification.types';

@Injectable({
  providedIn: 'root',
})
export class ToastNotificationService {
  constructor(protected snackbar: MatSnackBar) {}
  /**
   * Open a custom snackbar notification
   * @param options ToastNotificationOptions
   */
  public open(options: openSnackbarOptions): void {
    this.snackbar.openFromComponent(ToastComponent, {
      data: { type: options.type || MessageType.INFO, message: options.message },
      duration: options.duration || 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: this.type(options.type),
    });
  }
  /**
   * Returns CSS class name for different snackbar types
   */
  private type(type: string = MessageType.INFO): string {
    return CssClass[type];
  }
}
