<mat-form-field class="example-full-width" appearance="outline">
  <mat-label
    >{{ options?.labelText }}<span *ngIf="isRequired">*</span></mat-label
  >
  <mat-select
    [id]="id"
    [value]="value"
    (change)="onChange($any($event).target.value)"
    (blur)="onTouched()"
    [formControl]="$any(ngControl).control"
    [placeholder]="placeholder"
  >
    <mat-option>
      <ngx-mat-select-search
        [formControl]="selectSearchCtrl"
        [searching]="searching"
        noEntriesFoundLabel="No matching records found"
        placeholderLabel="Search"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let item of items; let i = index"
      [value]="item[options.valueKey]"
      [ngClass]="{ 'two-rows-option': twoRowsClass }"
    >
      <div class="option-label">
        <ng-container *ngIf="options.optionKey?.main">
          <ng-container
            *ngFor="let label of options.optionKey?.main; let i = index"
          >
            <p class="m-0 main" *ngIf="item[label]">
              {{ item[label] }}{{ addSeparator(i, item, "main") }}
            </p>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="options.optionKey?.getMainKeys">
          <ng-container
            *ngFor="
              let label of getKeyFields(item, 'getMainKeys');
              let i = index
            "
          >
            <p class="m-0 main" *ngIf="item[label]">
              {{ item[label] }}{{ addSeparator(i, item, "main") }}
            </p>
          </ng-container>
        </ng-container>
      </div>
      <div class="option-label">
        <ng-container *ngIf="options.optionKey?.sub">
          <p
            class="sub gray m-0"
            *ngFor="let label of options.optionKey?.sub; let i = index"
          >
            {{ item[label] }}{{ addSeparator(i, item, "sub") }}
          </p>
        </ng-container>
        <ng-container *ngIf="options.optionKey?.getSubKeys">
          <p
            class="sub gray m-0"
            *ngFor="
              let label of getKeyFields(item, 'getSubKeys');
              let i = index
            "
          >
            {{ item[label] }}{{ addSeparator(i, item, "sub") }}
          </p>
        </ng-container>
      </div>
    </mat-option>
  </mat-select>
  <mat-error *ngIf="ngControl.control?.hasError('required')">
    This field is required
  </mat-error>
  <mat-error *ngIf="ngControl.control?.hasError('maxlength')">
    Maximum
    {{ $any(ngControl).control.errors["maxlength"].requiredLength }} characters
    are allowed
  </mat-error>
  <mat-error *ngIf="ngControl.control?.hasError('minlength')">
    Minimum
    {{ $any(ngControl).control.errors["minlength"].requiredLength }} characters
    are required
  </mat-error>
  <mat-error *ngIf="ngControl.control?.hasError('pattern')">
    Invalid format
  </mat-error>
</mat-form-field>
