import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { QuoteService } from 'src/app/shared/services/quote.service';

@Component({
    selector: 'app-quote-file-upload',
    templateUrl: './quote-file-upload.component.html',
    styleUrls: ['./quote-file-upload.component.scss']
})
export class QuoteFileUploadComponent implements OnInit {

    public fileForm = new FormGroup({
        file: new FormControl(null, [Validators.required]),
        file_category: new FormControl('GENERAL', [Validators.required])
    })
    public errorMessage = new BehaviorSubject<string | null>(null);
    public fileName = '';
    private file: File | null = null;
    public isSubmitting = false;
    public progress = 0;

    constructor(private quoteService: QuoteService, private matDialog: MatDialogRef<QuoteFileUploadComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: number }) { }

    ngOnInit(): void {
    }

    onFileSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.file = file
            this.fileName = file.name;
        }
    }

    uploadFile() {
        this.errorMessage.next(null)
        if (this.fileForm.valid) {
            this.fileForm.disable()
            console.log('file')
            this.quoteService.addFile(
                this.data.id,
                this.file!,
                this.fileForm.controls.file_category.value
            )
                .subscribe(
                    data => {
                        this.fileForm.enable()
                        this.matDialog.close()
                    },
                    errorResponse => {
                        this.fileForm.enable()
                        this.errorMessage.next(errorResponse.error.message)
                    }
                )
        }
    }
}
