<mat-form-field class="example-full-width" appearance="outline">
  <mat-label
    >{{ options?.labelText }}<span *ngIf="isRequired">*</span></mat-label
  >
  <input
    matInput
    type="options.type"
    [formControl]="$any(ngControl).control"
    [id]="id"
    [value]="value"
    (change)="onChange($any($event).target.value)"
    (blur)="onTouched()"
    [placeholder]="options?.placeholder || ''"
  />
  <mat-error *ngIf="ngControl.control?.hasError('required')">
    This field is required
  </mat-error>
  <mat-error *ngIf="ngControl.control?.hasError('maxlength')">
    Maximum
    {{ $any(ngControl).control.errors["maxlength"].requiredLength }} characters
    are allowed
  </mat-error>
  <mat-error *ngIf="ngControl.control?.hasError('minlength')">
    Minimum
    {{ $any(ngControl).control.errors["minlength"].requiredLength }} characters
    are required
  </mat-error>
  <mat-error *ngIf="ngControl.control?.hasError('pattern')">
    Invalid format
  </mat-error>
</mat-form-field>
