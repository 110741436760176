import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class QuoteErrorInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
    .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
            const status = errorResponse.status
            switch(status){
                case 422:
                    console.error(errorResponse.error.errors);
                    return throwError(errorResponse)
                default:
                    console.error(errorResponse.error.message);
                    return throwError(errorResponse)
            }
        })
    );
  }
}
