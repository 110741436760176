import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { MockedDataService } from '../services/mocked-data/mocked-data.service';

@Injectable()
export class MockedDataInterceptor implements HttpInterceptor {

  constructor(private mockedDataService: MockedDataService) {}

/**
 * Interceptor to modify the request by adding mocked data to the query parameters.
 * @param request - The original HTTP request.
 * @param next - The next HTTP handler.
 * @returns An observable of the HTTP event stream with the modified request.
 */
intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const mockedDataMap = this.mockedDataService.mockedDataMap.getValue();
    let clonedRequest = request.clone();
    mockedDataMap.forEach((value, key) => {
        const prefixedKey = key.startsWith('mocked_') ? key : `mocked_${key}`;
        if(value){
            clonedRequest = clonedRequest.clone({
                params: clonedRequest.params.set(prefixedKey, value.toString())
            });
        }
    });
    return next.handle(clonedRequest);
}
}
