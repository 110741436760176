<a (click)="clickit($event)" class="btn btn-volvo-dark-blue btn-circle shadow btn-message rise-animation">
    <!-- <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
        <path
            d="M2.317,10.332l3.982,3.982c0.394,0.394,1.022,0.424,1.451,0.07l10.653-8.786L9.617,16.25 c-0.354,0.429-0.324,1.058,0.07,1.451l3.982,3.982c0.559,0.559,1.509,0.348,1.779-0.395l6.486-17.837 c0.313-0.862-0.522-1.698-1.384-1.384L2.712,8.553C1.969,8.823,1.758,9.773,2.317,10.332z" />
    </svg> -->
    <svg fill="#ffffff" viewBox="0 0 20 20" width="22px" height="22px"><path d="M21.29,8.55,3.45,2.07A1.07,1.07,0,0,0,2.07,3.45L8.55,21.29a1.08,1.08,0,0,0,1.78.39l4-4a1.07,1.07,0,0,0,.07-1.45L5.6,5.6l10.65,8.78a1.07,1.07,0,0,0,1.45-.07l4-4A1.08,1.08,0,0,0,21.29,8.55Z" transform="translate(-2 -2)"/></svg>
</a>
<div *ngIf="show" (click)="$event.stopPropagation()" class="message-form rounded border p-4 animate-opacity">
    {{httpMessage}}
    <div class="mb-3" *ngIf="!httpMessage">
        <label for="exampleFormControlTextarea1" class="form-label fw-bolder"><small>Question about a quote? Send us
                a message.</small></label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" [(ngModel)]="message"></textarea>
    </div>
    <div class="col" *ngIf="!httpMessage">
        <button mat-flat-button class="float-end btn-volvo-dark-blue" (click)="sendMessage()" [disabled]="isSubmitting">
            Send
        </button>
    </div>
</div>