import { Component, Input } from '@angular/core';
import { Validators } from '@angular/forms';

import { FormControlComponent } from '../form-control/form-control.component';
import { InputOptions } from './input.types';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
})
export class InputComponent extends FormControlComponent {
  private _options!: InputOptions;

  @Input() public set options(value: InputOptions) {
    this._options = value;
  }

  public get options() {
    return this._options;
  }

  public get isRequired() {
    return this.ngControl?.control?.hasValidator(Validators.required);
  }
}
