<div class="row snack-container toast-notification">
    <div class="col-1">
      <mat-icon>{{getIcon}}</mat-icon>
    </div>
    <div class="col">
      <span>{{data.message}}</span>
    </div>
</div>
<div class="d-flex justify-content-end">
    <button class="mat-flat-button mat-primary" mat-flat-button (click)="close()">Close</button>
</div>