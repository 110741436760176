import { SystemInfoService } from './services/system-info/system-info.service';
import { ToastNotificationComponent } from './../core/components/toast-notification/toast-notification.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {NgcCookieConsentModule} from 'ngx-cookieconsent';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { QuoteService } from './services/quote.service';
import { AppNavigationComponent } from './components/app-navigation/app-navigation.component';
import { ChatMessengerComponent } from './components/chat-messenger/chat-messenger.component';
import { LoadingScreenService } from './services/loading-screen/loading-screen.service';
import { RouterModule } from '@angular/router';
import { ChatMessageService } from './services/chat-mesage/chat-message.service';
import { FormsModule } from '@angular/forms';
import { EnrolledUnitService } from './services/enrolled-unit.service';
import { ClaimService } from './services/claim/claim.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { QuoteFormService } from './services/quote-form/quote-form.service';
import { EsppQuoterModule } from '../modules/espp/modules/espp-quoter/espp-quoter.module';
import { EsppQuoterComponent } from '../modules/espp/modules/espp-quoter/espp-quoter.component';
import { EpgDialogModule } from '../modules/epg-dialog/epg-dialog.module';
import { AppInformationService } from './services/app-information/app-information.service';
import { MaterialModule } from './material.module';
import { ShortDatePipe } from './pipes/short-date/short-date.pipe';
import { QueueLandingIconComponent } from './components/queue-landing-icon/queue-landing-icon.component';
import { EpgDialogComponent } from './components/epg-dialog/epg-dialog.component';
import { EpgDialogService } from './components/epg-dialog/epg-dialog.service';
import { EpgDatePipe } from './pipes/epg-date.pipe';
import { DebugPanelComponent } from './components/debug-panel/debug-panel.component';
import { DebugService } from './components/debug-panel/debug.service';
import { ErrorMessageDirective } from './directives/error-message.directive';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OrderByPipe } from './pipes/order-by.pipe';
import {MatChipsModule} from '@angular/material/chips';
import { HideIfUnauthorizedDirective } from '../core/directives/authorization/hide-if-unauthorized.directive';
import { ToastNotificationService} from './services/toast-notification/toast-notification.service';
import { ToastComponent } from './services/toast-notification/toast-component/toast.component';
import { DownloadPDFComponent, DownloadPDFService } from './services/download-pdf';



const MatModules = [
    MatSnackBarModule,
    MatDialogModule,
    MatSidenavModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSelectModule,
    MatRadioModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatCardModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatChipsModule,
];
@NgModule({
  declarations: [DownloadPDFComponent, ToastComponent, HideIfUnauthorizedDirective, CookieConsentComponent, LoadingScreenComponent, ChatMessengerComponent, AppNavigationComponent, ShortDatePipe,EpgDatePipe, QueueLandingIconComponent, EpgDialogComponent,DebugPanelComponent,ErrorMessageDirective,ContactUsComponent, OrderByPipe],
  imports: [
    NgcCookieConsentModule,
    CommonModule,
    RouterModule,
    FormsModule,
    OverlayModule,
    EsppQuoterModule,
    EpgDialogModule,
    MaterialModule,
    MatProgressSpinnerModule,
    MatChipsModule,
  ],
  exports: [DownloadPDFComponent, ToastComponent, HideIfUnauthorizedDirective, CookieConsentComponent, LoadingScreenComponent,ChatMessengerComponent,AppNavigationComponent,EsppQuoterComponent,...MatModules, ShortDatePipe,QueueLandingIconComponent,EpgDialogComponent,EpgDatePipe,DebugPanelComponent,ErrorMessageDirective,ContactUsComponent, OrderByPipe,],
  providers: [SystemInfoService, DownloadPDFService, ToastNotificationService, LoadingScreenService, ChatMessageService, EnrolledUnitService,QuoteFormService,DatePipe, EpgDialogService]
})
export class SharedModule { }