import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, map, catchError, filter } from 'rxjs/operators';
import { EsppQuoteType } from 'src/app/core/enum/EsppQuoteType';
import { Pin17Service, SerialNumber } from '../services/pin17/pin17.service'

@Injectable()
export class VolvoModelValidator {

    public static validateModel(modelService: Pin17Service,quoteType: EsppQuoteType | null) {
        return (group: AbstractControl): Observable<ValidationErrors | null> => {
            //const serialNumber = group.get('serial_number')
            const pin17 = group.get('pin17')
            if (!pin17?.value) return of(null);
            return modelService.searchPin17(pin17?.value)
                .pipe(
                    take(1),
                    map((data: SerialNumber[]) => {
                        const validModels = data.filter(model => model.pin_17 == pin17?.value)
                        console.log(validModels)
                        console.log(validModels.length < 1 ? { modelExists: true } : null)
                        return validModels.length < 1 ? { modelExists: true } : null;
                    })
                );
        }
    }

    //VCEL120HL0S632943
    public static model(modelService: Pin17Service) {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            const pin17 = control.value
            if(!pin17) return of(null);
            if(pin17.length < 17 || pin17.length > 17) return of({ modelExists: true });
            return modelService.searchPin17(pin17)
                .pipe(
                    take(1),
                    map((data: SerialNumber[]) => {
                        const validModels = data.filter(model => model.pin_17 == pin17)
                        return validModels.length < 1 ? { modelExists: true } : null;
                    })
                );
        }
    }
}