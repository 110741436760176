<form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
    <div class="login-form">
        <div class="d-flex justify-content-center">
            <div class="mb-3 text-center">
                <div class="brand-login mx-auto mb-4"></div>
                <h3 id="login-header">Enter New Password</h3>
            </div>
        </div>
        <!-- <div class="form-floating">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email">
                <mat-icon matSuffix>alternate_email</mat-icon>
            </mat-form-field>
        </div> -->
        <div class="form-floating mb-3">
            <mat-form-field class="example-full-width mb-0" appearance="outline">
                <mat-label>Password</mat-label>
                <input [type]="hide ? 'password' : 'text'" type="text" formControlName="password" matInput>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off'
                    : 'visibility'}}</mat-icon>
            </mat-form-field>
        </div>
        <div class="form-floating mb-3">
            <mat-form-field class="example-full-width mb-0" appearance="outline">
                <mat-label>Password Confirmation</mat-label>
                <input [type]="hide ? 'password' : 'text'" type="text" formControlName="password_confirmation" matInput>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off'
                    : 'visibility'}}</mat-icon>
            </mat-form-field>
        </div>
        <div class="d-grid">
            <button type="submit" mat-flat-button color="primary" class="mt-2 mb-2" [disabled]="!resetPasswordForm.valid">Reset Password</button>
        </div>

        <!-- <div class="text-center mt-4">
            <a [routerLink]="['/register']" class="small me-4">Register Here</a>
            <a class="small" [routerLink]="['../../forgot_password']">Reset Password</a>
        </div> -->
        <div class="text-center mt-4">
            <button mat-stroked-button class="rm-light me-2" routerLink="/register">Register Here</button>
            <button mat-stroked-button class="rm-light" routerLink="./forgot_password">Reset Password</button>
        </div>

    </div>
</form>