import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Validators, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { FormControlComponent } from '../form-control/form-control.component';
import { SelectSearchOptions } from './select-search.types';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent
  extends FormControlComponent
  implements OnInit
{
  private _options!: SelectSearchOptions;
  private _items!: any[];
  public selectSearchCtrl: FormControl = new FormControl(null);
  public searching: boolean = false;

  /**
   * Emitter event to send filter value to parent component
   */
  @Output() onFilterChange = new EventEmitter<string>();
  /**
   * Set custom options
   */
  @Input() public set options(value: SelectSearchOptions) {
    this._options = value;
  }
  /**
   * Set items to be displayed as options in dropdown
   */
  @Input() public set items(values: any[]) {
    this._items = values;
  }
  /**
   * get CSS class for option items
   */
  public get twoRowsClass() {
    return this.options.optionKey.main && this.options.optionKey.sub;
  }
  /**
   * get options
   */
  public get options() {
    return this._options;
  }
  /**
   * get items
   */
  public get items() {
    return this._items;
  }
  /**
   * is required filed
   */
  public get isRequired() {
    return this.ngControl?.control?.hasValidator(Validators.required);
  }
  /**
   * custom placeholder
   */
  public get placeholder() {
    return `${this.options?.placeholder || ''} ${this.isRequired && '*'}`;
  }
  /**
   * @description Additional function to be able to handle dynamic fields through a function
   */
  public getKeyFields(item: any, keyType: 'getMainKeys' | 'getSubKeys'): any {
    const keys = this.options.optionKey?.[keyType]?.(item);
    return keys;
  }
  /**
   * emit value when filter changed
   */
  private filterChange(value: string) {
    this.onFilterChange.emit(value);
  }
  /**
   * Add a come when need
   * @param index
   * @param item
   * @param key
   * @returns separator coma
   */
  public addSeparator(index: number, item: any, key: 'main' | 'sub') {
    const nextKeyField = this.options?.optionKey[key]?.[index + 1] || -1;
    if (item[nextKeyField]) {
      if (this.options?.removeSeparator) {
        return '';
      }
      return ',';
    } else {
      return '';
    }
  }
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  ngOnInit(): void {
    this.selectSearchCtrl.valueChanges
      .pipe(
        tap(() => (this.searching = true)),
        takeUntil(this._onDestroy),
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe((filter: string) => {
        this.filterChange(filter);
      });
  }
}
