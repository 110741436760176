import { Injectable } from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { DebugPanelComponent } from './debug-panel.component';

@Injectable({
  providedIn: 'root'
})
export class DebugService {

  constructor(private bottomsheet: MatBottomSheet) { }

  public open(data: Array<any>){
    this.bottomsheet.open(DebugPanelComponent,{data: {components: data}})
  }

  downloadFile(data: Map<string, any>) {
    const keys =[ ...data.keys() ];
    const d = (keys.reduce((prev,current) => {
        return {...prev,...{[current]: data.get(current)}}
    },{}))
    const blob = new Blob([...JSON.stringify(d)], { type: 'text/text' });
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  }
}
