export enum HttpStatusCodeRange {
    SUCCESS_RANGE, CLIENT_ERROR_RANGE, SERVER_ERROR_RANGE, UNKNOWN }

export class HttpStatusCodeRangeUtil {
    public static getRange(code: number): HttpStatusCodeRange {
        if (code >= 200 && code < 300) {
            return HttpStatusCodeRange.SUCCESS_RANGE;
        } 
        if (code >= 400 && code < 500) {
            return HttpStatusCodeRange.CLIENT_ERROR_RANGE;
        } 
        if (code >= 500 && code < 600) {
            return HttpStatusCodeRange.SERVER_ERROR_RANGE;
        } 
        return HttpStatusCodeRange.UNKNOWN;
    }
}

export const generateErrorMessage = (errorResonse) => {
    var errorMessage = '';
    const range: HttpStatusCodeRange = HttpStatusCodeRangeUtil.getRange(errorResonse.status);
    const baseError = errorResonse.error.message;  
    const requestID = (errorResonse.headers.get('request-id')).split('-');
    switch (range) {
        case HttpStatusCodeRange.CLIENT_ERROR_RANGE:  
            errorMessage = `${baseError}`;
            break;
        case HttpStatusCodeRange.SERVER_ERROR_RANGE:
            errorMessage = `${baseError} Please contact the program administrator at (866) 408-2881 for assistance. Error ID: ${requestID[0]}`;
            break;
        default:
            errorMessage = `${baseError}`;
            break;
    }
    return `Error: ${errorMessage}`;
}