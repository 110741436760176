<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <mat-card></mat-card>
            <mat-card class="example-card">
                <mat-card-title>Reset Password</mat-card-title>
                <mat-card-content>
                    <form (ngSubmit)="onSubmit()" [formGroup]="resetPasswordForm">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Email</mat-label>
                            <input type="text" matInput placeholder="Email" formControlName="email">
                          </mat-form-field>
                          <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Password</mat-label>
                            <input type="password" matInput placeholder="Email" formControlName="password" >
                          </mat-form-field>
                          <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Confirm Password</mat-label>
                            <input type="password" matInput placeholder="Email" formControlName="password_confirmation">
                          </mat-form-field>
                          <button [disabled]="!resetPasswordForm.valid" mat-button>Reset Password</button>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>