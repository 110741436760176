

const getHostname = (): string => {
    return window.location.hostname.replace('www.', '');
}
export const oktaClientID = (): string => {
    switch (getHostname()) {
        //production urls
        case 'espp.epgins.com':
        case 'ppp.cnhinsurance.com':
            return '0oaqpjy02gS43glou1t7';
        //model clientid
        case 'espp-integration-model.epgins.com':
        case 'espp-model.epgins.com':
            return '0oa1h58jd2ote0prX0h8';
        //default to dev okta env
        default:
            return '0oa1f9ffd38nw98Y80h8';
    }
}

export const oktaBaseUrl = (): string => {
    switch (getHostname()) {
        //production urls
        case 'espp.epgins.com':
        case 'ppp.cnhinsurance.com':
            return 'https://assurant.okta.com';
        //model clientid
        case 'espp-integration-model.epgins.com':
        case 'espp-model.epgins.com':
            return 'https://idm-model.assurant.com';
        //default to dev okta env
        default:
            return 'https://idm-dev.assurant.com';
    }
}


export default {
    oidc: {
        clientId: oktaClientID(),
        issuer: oktaBaseUrl(),
        redirectUri: window.location.pathname + '//' + window.location.hostname + '/sso',
        scopes: ['openid', 'email', 'profile', 'groups'],
        testing: {
            disableHttpsCheck: false
        },
    },
    widget: {
        useInteractionCodeFlow: false,
    },
    resourceServer: {
        messagesUrl: 'http://localhost:8000/api/messages',
    },

};