import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AppInformationService } from 'src/app/shared/services/app-information/app-information.service';


@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {
    private featureFlags!: any[]

    constructor(
        private authService: AuthenticationService,
        public dialogRef: MatDialogRef<MessageDialogComponent>,
        private appInfo: AppInformationService,
        ) { 
        
    }

    ngOnInit(): void {
        this.appInfo.featureFlags.subscribe((flags) => this.featureFlags = flags)
    }

    public get canStartClaim(): boolean {
        // If feature flag is present then don't display the button
            if(this.featureFlags.length > 0) {
                return this.featureFlags.findIndex(flags => flags.name === 'CLAIMS_DECOM') > -1 ? false : true;
            }
            return true
    }

    public get user() {
        return this.authService.user;
    }

    public get currentMfrProgram(): string {
        if (this.user == null) return 'EPG';
        const mfrProgram = this.user.mfr_programs.find(program => program.current == true);
        return mfrProgram?.name ?? 'EPG';
    }

    closeDialog() {
        this.dialogRef.close();
    }

}
