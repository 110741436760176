<div class="px-4 py-5">
    <h3 class="mb-4 text-center">About eWarranty</h3>

    <!-- <div [ngSwitch]="currentMfrProgram">
        <ng-container *ngSwitchCase="'CNH'">
            display for CNH user
            
        </ng-container>
        <ng-container *ngSwitchDefault>
            display for EPG user
            <div>
                <h5 class="d-inline">line1</h5>
                <p class="pt-3">line2</p>
                <p>line3</p>
                <p>line4</p>
            </div>
        </ng-container>
    </div> -->

    <h5 class="d-inline" *ngIf="canStartClaim; else updatedMessage">
        PPP on eWarranty is live. Please submit all PPP claims including New, Used, Allied or Legacy plans through eWarranty. Anything started in the PPP system will be processed as normal.
    </h5>
    <ng-template #updatedMessage>
        <h5 class="d-inline">
            PPP on eWarranty is live. Please submit all PPP claims including New, Used, Allied or Legacy plans through eWarranty.
        </h5>
    </ng-template>
    
</div>
<div class="text-center">
        <p class="mb-4 btn btn-link" role="button" (click)="closeDialog()">Proceed to Claims in the PPP system</p>
</div>